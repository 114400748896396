import { Spin } from 'antd';
import React from 'react';

const PageSpinner: React.FC = () => {
  return (
    <div className="centered">
      <Spin size="large" />
    </div>
  );
};

export default PageSpinner;
