import { RootState } from './../app.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILocationsState {
  locations: { [key: string]: string };
  locationPreferences: { [key: string]: any };
}

export const initialState: ILocationsState = {
  locations: {},
  locationPreferences: {},
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocations: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.locations = action.payload;
    },
    setLocationPreferences: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.locationPreferences = action.payload;
    },
    clearLocations: (state) => {
      state.locations = {};
    },
  },
});

// A selector
export const locationsSelector = (state: RootState) => state.locations;

// Actions
export const { setLocations, clearLocations, setLocationPreferences } = locationsSlice.actions;

// The reducer
export default locationsSlice.reducer;
