import { lookup_info } from './../../backend/API';
import { RootState } from '../app.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILookup extends lookup_info {
  display: string;
}

interface ILookupState {
  lookup: Array<ILookup>;
}

export const initialState: ILookupState = {
  lookup: [],
};

export const lookupSlice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    setLookup: (state, action: PayloadAction<Array<ILookup>>) => {
      state.lookup = action.payload;
    },
    clearLookup: (state) => {
      state.lookup = [];
    },
  },
});

// A selector
export const lookupSelector = (state: RootState) => state.lookup;

// Actions
export const { setLookup, clearLookup } = lookupSlice.actions;

// The reducer
export default lookupSlice.reducer;
