/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  210750284236: {
    aws_project_region: 'ap-southeast-2',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_IrdAZTXcw',
    aws_user_pools_web_client_id: '5f7fju3mjireq205ovnss2ctot',
    oauth: {},
    aws_appsync_graphqlEndpoint:
      'https://qfdbqejn7fhnzklt7wvdxs5cd4.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    aws_appsync_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-hqd7mx35zfgynbnfazupsoz3ku',
  },
  590183810321: {
    aws_project_region: 'ap-southeast-2',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_TmvRLaF9Y',
    aws_user_pools_web_client_id: '2ijj113d4pstu7rk8npn2in0h9',
    oauth: {},
    aws_appsync_graphqlEndpoint:
      'https://l46ptisambdenbj6cnccjp44ea.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    aws_appsync_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-wbo2e3eljvfqfid3gsqxicjxwi',
  },
};

export default awsmobile;
