import { getMapForCid } from "../constants/customisation";

export enum Language {
  en = 'en',
  id = 'id',
}

const DEFAULT_LANGUAGE = {
  "default": Language.en,
  17: Language.id,
  44: Language.id
}

export const getDefaultLanguage = () => {
  const result = getMapForCid(DEFAULT_LANGUAGE);
  return result;
};
