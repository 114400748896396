/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type quote_info = {
  __typename: "quote_info",
  amount?: number | null,
  id: number,
  quote_num?: string | null,
  type?: string | null,
  Claim_id?: number | null,
  Claim_Repairer_id?: number | null,
};

export type quote_item_info = {
  __typename: "quote_item_info",
  id?: number | null,
  amount?: number | null,
  type?: string | null,
  description?: string | null,
  name?: string | null,
  discount?: number | null,
  gst?: number | null,
};

export type quote_req_info = {
  __typename: "quote_req_info",
  state?: number | null,
  distance?: number | null,
  name?: string | null,
  Claim_Claim_id?: number | null,
  Claim_Repairer_id?: number | null,
};

export enum AssetConditionStatus {
  DAMAGED = "DAMAGED",
  UNDAMAGED = "UNDAMAGED",
}


// The integer value is used in the AssetStatus
// #  First enum has value 0, next 1 etc.
export enum AssetRentalStatus {
  CHECKEDIN = "CHECKEDIN",
  CHECKOUT = "CHECKOUT",
  READYLINE = "READYLINE",
  UNAVAILABLE = "UNAVAILABLE",
}


export type Car = {
  __typename: "Car",
  carmodelinfo?: string | null,
  cartype?: number | null,
  cid: number,
  colour?: string | null,
  conditionstatus?: AssetConditionStatus | null,
  creation?: string | null,
  event?: string | null,
  heighttype?: string | null,
  lastseen?: string | null,
  model?: string | null,
  odometer?: number | null,
  rentalagreement?: string | null,
  rentalstatus?: AssetRentalStatus | null,
  secondaryid?: string | null,
  sitename?: string | null,
  statelicense: string,
};

export enum CarFaceType {
  BACK = "BACK",
  BACK_LEFT = "BACK_LEFT",
  BACK_RIGHT = "BACK_RIGHT",
  FRONT = "FRONT",
  FRONT_LEFT = "FRONT_LEFT",
  FRONT_RIGHT = "FRONT_RIGHT",
  LEFT = "LEFT",
  LEFT_BOTTOM = "LEFT_BOTTOM",
  LEFT_TOP = "LEFT_TOP",
  RIGHT = "RIGHT",
  RIGHT_BOTTOM = "RIGHT_BOTTOM",
  RIGHT_TOP = "RIGHT_TOP",
  ROOF = "ROOF",
}


export type CarAlignment = {
  __typename: "CarAlignment",
  comparedevent: string,
  creationdatetime: string,
  event: string,
  ftype: CarFaceType,
  isRelatedCheckoutEvent: boolean,
  jsonstr: string,
  order: string,
};

export type CarFace = {
  __typename: "CarFace",
  alignment?: string | null,
  damagecount?: number | null,
  event: string,
  imagetotal?: number | null,
  maskcount?: number | null,
  maxsentimentint?: number | null,
  order?: string | null,
  selectioncount?: number | null,
  type: CarFaceType,
};

export type Customer = {
  __typename: "Customer",
  datetime?: string | null,
  email?: string | null,
  event: string,
  phone?: string | null,
  status?: string | null,
};

export enum DebugType {
  APPUPLOADS = "APPUPLOADS",
  OTHER = "OTHER",
  SYSERR = "SYSERR",
  SYSREP = "SYSREP",
}


export type Debug = {
  __typename: "Debug",
  datetime: string,
  debugtype?: DebugType | null,
  id: string,
  msg?: string | null,
};

export type CreateDevCrowdusersInput = {
  email: string,
  uploaded?: number | null,
};

export type DevCrowdusers = {
  __typename: "DevCrowdusers",
  email: string,
  uploaded?: number | null,
};

export enum EventType {
  CHECKIN = "CHECKIN",
  CHECKOUT = "CHECKOUT",
}


export type Event = {
  __typename: "Event",
  cid: number,
  completed?: boolean | null,
  conditionProperties?: string | null,
  customerfueled?: boolean | null,
  custommetadata?: string | null,
  // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
  // # damage detection, 2 = TBD, 4 = TBD
  custompipeline?: number | null,
  datetime: string,
  distancetravelled?: number | null,
  eventtype: EventType,
  expectedfaces?: number | null,
  fuel?: string | null,
  id: string,
  imageTypes?: number | null,
  imagetotal?: number | null,
  invertedsentimentdatetime?: string | null,
  managerreviewed?: boolean | null,
  notificationTypes?: number | null,
  // The following support notifications. Initially for the API
  notificationURL?: string | null,
  observations?: string | null,
  odometer?: number | null,
  relatedcheckoutevent?: string | null,
  rentalagreement?: string | null,
  // Following is a bitcode where 0 means no alignment on any side and 15 all sides
  requestedalignment?: number | null,
  reviewstatus?: EventReviewStatus | null,
  risk?: Risk | null,
  sentiment?: DamageSentiment | null,
  sentimentdatetime?: string | null,
  signature?: string | null,
  sitename: string,
  statelicense: string,
  user?: string | null,
};

export enum EventReviewStatus {
  COMPLETED = "COMPLETED",
  ESCALATED = "ESCALATED",
  PENDING = "PENDING",
}


export enum Risk {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  NONE = "NONE",
}


// These are represented by an int in the DB with NONE=0, INTERESTED=1, etc.
export enum DamageSentiment {
  ALARMED = "ALARMED",
  INTERESTED = "INTERESTED",
  NONE = "NONE",
  SUSPICIOUS = "SUSPICIOUS",
  TROUBLED = "TROUBLED",
}


export type Image = {
  ai?: boolean,
  __typename: "Image",
  event: string,
  eventtype?: EventType | null,
  faceposition?: number | null,
  facetype?: CarFaceType | null,
  height?: number | null,
  id: string,
  // set by users manually
  istheredamage?: number | null,
  position?: number | null,
  processingstate?: string | null,
  s3path: string,
  selected?: boolean | null,
  totalCount?: number | null,
  width?: number | null,
};

export type ImageDamage = {
  __typename: "ImageDamage",
  annotation?: string | null,
  annotationsummary?: number | null,
  cid: number,
  event?: string | null,
  image: string,
  json?: string | null,
};

export type ImageMask = {
  __typename: "ImageMask",
  image: string,
  json?: string | null,
  masks3path?: string | null,
  event?: string | null,
};

export enum DamageAction {
  CHARGE = "CHARGE",
  CLAIM = "CLAIM",
  NONE = "NONE",
  REPORT = "REPORT",
  UNSET = "UNSET",
}


export enum DamageDuplication {
  BEST = "BEST",
  NO = "NO",
  OTHER = "OTHER",
}


export enum DamageSeverity {
  UNKNOWN = "UNKNOWN",
}


export enum DamageSource {
  AGGREGATOR = "AGGREGATOR",
  ANNOTATED = "ANNOTATED",
  HUMAN = "HUMAN",
  RAW = "RAW",
}


export enum DamageType {
  FAIRWEAR = "FAIRWEAR",
  IGNORE = "IGNORE",
  NEWDAMAGE = "NEWDAMAGE",
  PREEXISTING = "PREEXISTING",
  UNSET = "UNSET",
  UNSURE = "UNSURE",
}


export type RepDamage = {
  __typename: "RepDamage",
  action?: DamageAction | null,
  additionalinfo: string,
  part?: string | null,
  boundingbox: string,
  cid: number,
  confidence: number,
  datetime: string,
  duplicate: DamageDuplication,
  event: string,
  face: CarFaceType,
  id: string,
  image: string,
  preexistingcid_asset?: string | null,
  position: number,
  relatedregion?: string | null,
  score: number,
  sentiment?: DamageSentiment | null,
  sentimentnum?: number | null,
  severity?: DamageSeverity | null,
  source: DamageSource,
  statelicense: string,
  type?: DamageType | null,
};

export type Report = {
  __typename: "Report",
  cid: number,
  comparedevent: string,
  id: string,
  items?: string | null,
  reportdatetime: string,
  reportevent: string,
  status: number,
  text?: string | null,
};

export type Site = {
  __typename: "Site",
  brand?: string | null,
  cid: number,
  company_name?: string | null,
  country?: string | null,
  district?: string | null,
  location?: string | null,
  region?: string | null,
  site?: string | null,
  siteid?: number | null,
  sitename: string,
  state?: string | null,
  statusrequest?: number | null,
};

export type DeleteDevCrowdusersInput = {
  email: string,
};

export type UpdateDevCrowdusersInput = {
  email: string,
  uploaded?: number | null,
};

export type claim_stats = {
  __typename: "claim_stats",
  cnt_claims?: number | null,
  cnt_requested?: number | null,
  cnt_accepted?: number | null,
  cnt_received?: number | null,
};

export type PaginatedDamage = {
  __typename: "PaginatedDamage",
  nextToken?: string | null,
  damages?:  Array<damage_info | null > | null,
};

export type damage_info = {
  __typename: "damage_info",
  image_url?: string | null,
  description?: string | null,
  location?: string | null,
  type?: string | null,
  car_reg?: string | null,
  car_id?: number | null,
  damage_id?: number | null,
};

export type ClaimConfidence = {
  __typename: "ClaimConfidence",
  confidence?: number | null,
};

export type lookup_info = {
  __typename: "lookup_info",
  state?: number | null,
  description?: string | null,
};

export type PaginatedEvent = {
  __typename: "PaginatedEvent",
  events?:  Array<Event | null > | null,
  nextToken?: string | null,
};

export type PaginatedCar = {
  __typename: "PaginatedCar",
  cars?:  Array<Car | null > | null,
  nextToken?: string | null,
};

export type claimFilterInput = {
  state?: number | null,
  recv_date_start?: string | null,
  recv_date_end?: string | null,
};

export type PaginatedClaim = {
  __typename: "PaginatedClaim",
  claims?:  Array<claim_info | null > | null,
  nextToken?: string | null,
  count?: number | null,
  limit?: number | null,
  filter?: string | null,
};

export type claim_info = {
  __typename: "claim_info",
  car_reg?: string | null,
  claim_num?: string | null,
  description?: string | null,
  model?: string | null,
  policy_num?: string | null,
  recv_date?: string | null,
  state?: number | null,
  year?: number | null,
  id?: number | null,
  initial_amount?: number | null,
  final_amount?: number | null,
  age?: number | null,
  analyst?: string | null,
  notes?: string | null,
  location?: string | null,
};

export type PaginatedImageDamage = {
  __typename: "PaginatedImageDamage",
  imageDamages:  Array<ImageDamage >,
  nextToken?: string | null,
};

export type EventFilterInput = {
  cid?: TableIntFilterInput | null,
  completed?: TableBooleanFilterInput | null,
  datetime?: TableStringFilterInput | null,
  eventtype?: TableEventTypeFilterInput | null,
  managerreviewed?: TableBooleanFilterInput | null,
  mva?: TableStringFilterInput | null,
  odometer?: TableIntFilterInput | null,
  relatedcheckoutevent?: TableStringFilterInput | null,
  rentalagreement?: TableStringFilterInput | null,
  signature?: TableStringFilterInput | null,
  sitename?: TableStringFilterInput | null,
  statelicense?: TableStringFilterInput | null,
};

export type TableIntFilterInput = {
  between?: Array< number | null > | null,
  contains?: number | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
  notContains?: number | null,
};

export type TableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type TableStringFilterInput = {
  attributeExists?: boolean | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
};

export type TableEventTypeFilterInput = {
  eq?: string | null,
  ne?: string | null,
};

export type PaginatedSite = {
  __typename: "PaginatedSite",
  nextToken?: string | null,
  sites:  Array<Site >,
};

export type AppSettings = {
  __typename: "AppSettings",
  account?: string | null,
  apppreference?: string | null,
  appworkflow?: string | null,
  cid: number,
  sitename?: string | null,
};

export type AssetDashboardFilterInput = {
  // The sitename filter can only be a string "in".
  // #  If this field is not present all site names are returned.
  sitename?: TableStringInFilterInput | null,
};

export type TableStringInFilterInput = {
  in?: Array< string | null > | null,
};

export type AssetDashboard = {
  __typename: "AssetDashboard",
  checkedin?: number | null,
  checkedout?: number | null,
  readyline?: number | null,
  totalassets?: number | null,
  unavailable?: number | null,
};

export type AssetStatus = {
  __typename: "AssetStatus",
  cartype?: number | null,
  cid: number,
  conditionProperties?: string | null,
  conditionstatus?: AssetConditionStatus | null,
  creation?: string | null,
  event?: string | null,
  lastseen?: string | null,
  odometer?: number | null,
  rentalagreement?: string | null,
  rentalstatus?: AssetRentalStatus | null,
  sitename?: string | null,
  statelicense: string,
};

export type AssetStatusFilterInput = {
  // The conditionstatus filter can only be a enum "in"
  // #  If this field is not present all values are returned
  conditionstatus?: TableEnumInFilterInput | null,
  // The "between" first value is the earliest date. If empty it defaults to 1st Jan 2021.
  // #  The "between" second value is the latest date. If empty it defaults to 2050.
  creation?: TableStringBetweenFilterInput | null,
  // The last seen filter
  lastseen?: TableStringFilterInput | null,
  // TBD
  notes?: TableStringFilterInput | null,
  // The odometer filter
  // #  If this field is not present all values are returned.
  odometer?: TableIntFilterInput | null,
  // Set if the event with this rental agreement is to be returned
  rentalagreement?: TableStringEqualityFilterInput | null,
  // The rental status filter only can be a enum "in"
  // #  If this field is not present all rental statuses are returned
  rentalstatus?: TableEnumInFilterInput | null,
  // The sitename filter can only be a string "in".
  // #  If this field is not present all site names are returned.
  sitename?: TableStringInFilterInput | null,
  // Set if the event with this asset id is to be returned
  statelicense?: TableStringEqualityFilterInput | null,
};

export type TableEnumInFilterInput = {
  in?: Array< string | null > | null,
};

export type TableStringBetweenFilterInput = {
  between?: Array< string | null > | null,
};

export type TableStringEqualityFilterInput = {
  eq?: string | null,
};

export type PaginatedAssetStatus = {
  __typename: "PaginatedAssetStatus",
  assetStatuses?:  Array<AssetStatus | null > | null,
  nextToken?: string | null,
};

export type CompanySettings = {
  __typename: "CompanySettings",
  apppreference?: string | null,
  appworkflow?: string | null,
  checkinemailhtmlfile: string,
  cid: number,
  companyfieldsfile: string,
  companyname?: string | null,
  emailhtmlbucket: string,
  emailhtmlfile: string,
  emailsource: string,
  emailsubject: string,
  webpreference?: string | null,
};

export type DamageFilterInput = {
  action?: TableEnumFilterInput | null,
  confidence?: TableFloatFilterInput | null,
  duplicate?: TableEnumFilterInput | null,
  severity?: TableEnumFilterInput | null,
  source?: TableEnumFilterInput | null,
  type?: TableEnumFilterInput | null,
};

export type TableEnumFilterInput = {
  eq?: string | null,
  ne?: string | null,
};

export type TableFloatFilterInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type PaginatedRepDamage = {
  __typename: "PaginatedRepDamage",
  nextToken?: string | null,
  repDamages?:  Array<RepDamage | null > | null,
};

export type claim_repairer_info = {
  __typename: "claim_repairer_info",
  car_reg?: string | null,
  claim_num?: string | null,
  description?: string | null,
  model?: string | null,
  policy_num?: string | null,
  recv_date?: string | null,
  state?: number | null,
  year?: number | null,
  age?: string | null,
};

export type JsonStringType = {
  __typename: "JsonStringType",
  json: string,
};

export type GantryCameraDetails = {
  __typename: "GantryCameraDetails",
  cameraid: string,
  cid?: number | null,
  site?: string | null,
  gantry?: string | null,
  camera?: string | null,
  fps?: number | null,
  processindex?: number | null,
  additionaljson?: string | null,
};

export type CarModelInfo = {
  __typename: "CarModelInfo",
  bodystyle?: string | null,
  id: string,
  make?: string | null,
  maxheight?: number | null,
  maxtyre?: number | null,
  minheight?: number | null,
  mintyre?: number | null,
  model?: string | null,
  variant?: string | null,
};

export type PaginatedReport = {
  __typename: "PaginatedReport",
  nextToken?: string | null,
  reports:  Array<Report >,
};

export type S3Image = {
  __typename: "S3Image",
  filename?: string | null,
  imagetype?: string | null,
  position?: number | null,
  s3path: string,
};

export type VersionInfo = {
  __typename: "VersionInfo",
  deprecationmessage?: string | null,
  severity?: number | null,
  version: string,
};

export type TableDevCrowdusersFilterInput = {
  email?: TableStringFilterInput | null,
  uploaded?: TableIntFilterInput | null,
};

export type DevCrowdusersConnection = {
  __typename: "DevCrowdusersConnection",
  items?:  Array<DevCrowdusers | null > | null,
  nextToken?: string | null,
};

export enum ReviewDateOrdering {
  ASC = "ASC",
  DESC = "DESC",
}


export type ReviewEventFilterInput = {
  // The ReviewEventDateTimeFilterInput only provide a String between option
  // #  The "between" first value is the earliest date. If empty it defaults to 1st Jan 2021.
  // #  The "between" second value is the latest date. If empty it defaults to 2050.
  datetime?: TableStringBetweenFilterInput | null,
  // Limit to specific eventtype, default is CHECKIN, but you can set here to CHECKOUT
  eventtype?: TableEventTypeFilterInput | null,
  // E.g. Use eq "G8" for only G8 and use between: ["G1", "G7"] to return not G8
  // #  If this field is not present it is equivalent to ALL
  fuel?: TableStringFilterInput | null,
  // TBD
  notes?: TableStringFilterInput | null,
  // Set if the event with this rental agreement is to be returned
  rentalagreement?: TableStringEqualityFilterInput | null,
  // The review status filter only can be a enum "in"
  // #  If this field is not present all review statuses are returned
  reviewstatus?: TableEnumInFilterInput | null,
  // The review status filter only can be a enum "in"
  // #  If this field is not present all review statuses are returned
  reviewstatusint?: any | null,
  // The sentiment filter can only be a enum "in"
  // #  If this field is not present all sentiments are returned
  sentiment?: TableEnumInFilterInput | null,
  // The sitename filter can only be a string "in".
  // #  If this field is not present all site names are returned.
  sitename?: TableStringInFilterInput | null,
  // Set if the event with this asset id is to be returned
  statelicense?: TableStringEqualityFilterInput | null,
};

export enum ReviewSentimentOrdering {
  ASC = "ASC",
  DESC = "DESC",
  NONE = "NONE",
}


export type PaginatedReviewEvent = {
  __typename: "PaginatedReviewEvent",
  nextToken?: string | null,
  reviewEvents?:  Array<ReviewEvent | null > | null,
};

export type ReviewEvent = {
  __typename: "ReviewEvent",
  cid: number,
  conditionProperties?: string | null,
  datetime: string,
  eventtype?: EventType | null,
  expectedfaces?: number | null,
  fuel?: string | null,
  id: string,
  imagetotal?: number | null,
  odometer?: number | null,
  relatedcheckoutevent?: string | null,
  rentalagreement?: string | null,
  reviewstatus?: EventReviewStatus | null,
  sentiment?: DamageSentiment | null,
  signature?: string | null,
  sitename?: string | null,
  statelicense?: string | null,
};

export type EventFilterInput_v2 = {
  cid?: TableIntFilterInput | null,
  completed?: TableBooleanFilterInput | null,
  datetime?: TableStringFilterInput | null,
  eventtype?: TableEventTypeFilterInput | null,
  managerreviewed?: TableBooleanFilterInput | null,
  mva?: TableStringFilterInput | null,
  odometer?: TableIntFilterInput | null,
  relatedcheckoutevent?: TableStringFilterInput | null,
  rentalagreement?: TableStringFilterInput | null,
  signature?: TableStringFilterInput | null,
  sitename?: TableInFilterInput | null,
  statelicense?: TableStringFilterInput | null,
};

export type TableInFilterInput = {
  in?: Array< string | null > | null,
};

export type CreateQuoteMutationVariables = {
  Claim_id?: number | null,
  Claim_Repairer_id?: number | null,
  quote_num?: string | null,
};

export type CreateQuoteMutation = {
  createQuote?:  {
    __typename: "quote_info",
    amount?: number | null,
    id: number,
    quote_num?: string | null,
    type?: string | null,
    Claim_id?: number | null,
    Claim_Repairer_id?: number | null,
  } | null,
};

export type CreateQuoteItemMutationVariables = {
  Quote_id?: number | null,
  amount?: number | null,
  type?: string | null,
  description?: string | null,
  name?: string | null,
  discount?: number | null,
  gst?: number | null,
};

export type CreateQuoteItemMutation = {
  createQuoteItem?:  {
    __typename: "quote_item_info",
    id?: number | null,
    amount?: number | null,
    type?: string | null,
    description?: string | null,
    name?: string | null,
    discount?: number | null,
    gst?: number | null,
  } | null,
};

export type CreateQuoteRequestMutationVariables = {
  Claim_id?: number | null,
  Claim_Repairer_id?: number | null,
  state?: string | null,
};

export type CreateQuoteRequestMutation = {
  createQuoteRequest?:  {
    __typename: "quote_req_info",
    state?: number | null,
    distance?: number | null,
    name?: string | null,
    Claim_Claim_id?: number | null,
    Claim_Repairer_id?: number | null,
  } | null,
};

export type UpdateQuoteAmountMutationVariables = {
  id?: number | null,
  amount?: number | null,
};

export type UpdateQuoteAmountMutation = {
  updateQuoteAmount?:  {
    __typename: "quote_info",
    amount?: number | null,
    id: number,
    quote_num?: string | null,
    type?: string | null,
    Claim_id?: number | null,
    Claim_Repairer_id?: number | null,
  } | null,
};

export type UpdateQuoteStatusMutationVariables = {
  Claim_Claim_id?: number | null,
  Claim_Repairer_id?: number | null,
  state?: number | null,
};

export type UpdateQuoteStatusMutation = {
  updateQuoteStatus?:  {
    __typename: "quote_req_info",
    state?: number | null,
    distance?: number | null,
    name?: string | null,
    Claim_Claim_id?: number | null,
    Claim_Repairer_id?: number | null,
  } | null,
};

export type CreateAdminUserMutationVariables = {
  username: string,
  userpwd: string,
  usertype: number,
  sites: string,
  defaultemail?: string | null,
  cid: number,
  cloudenvid: number,
};

export type CreateAdminUserMutation = {
  createAdminUser?: string | null,
};

export type CreateCarMutationVariables = {
  carmodelinfo?: string | null,
  cartype?: number | null,
  cid: number,
  colour?: string | null,
  conditionstatus?: AssetConditionStatus | null,
  creation?: string | null,
  event?: string | null,
  lastseen?: string | null,
  model?: string | null,
  odometer?: number | null,
  rentalagreement?: string | null,
  rentalstatus?: AssetRentalStatus | null,
  secondaryid?: string | null,
  sitename?: string | null,
  statelicense: string,
};

export type CreateCarMutation = {
  createCar?:  {
    __typename: "Car",
    carmodelinfo?: string | null,
    cartype?: number | null,
    cid: number,
    colour?: string | null,
    conditionstatus?: AssetConditionStatus | null,
    creation?: string | null,
    event?: string | null,
    heighttype?: string | null,
    lastseen?: string | null,
    model?: string | null,
    odometer?: number | null,
    rentalagreement?: string | null,
    rentalstatus?: AssetRentalStatus | null,
    secondaryid?: string | null,
    sitename?: string | null,
    statelicense: string,
  } | null,
};

export type CreateCarAlignmentMutationVariables = {
  comparedevent: string,
  creationdatetime: string,
  event: string,
  ftype: CarFaceType,
  isRelatedCheckoutEvent: boolean,
  jsonstr: string,
  order: string,
};

export type CreateCarAlignmentMutation = {
  createCarAlignment?:  {
    __typename: "CarAlignment",
    comparedevent: string,
    creationdatetime: string,
    event: string,
    ftype: CarFaceType,
    isRelatedCheckoutEvent: boolean,
    jsonstr: string,
    order: string,
  } | null,
};

export type CreateCarFaceMutationVariables = {
  alignment?: string | null,
  damagecount?: number | null,
  event: string,
  imagetotal?: number | null,
  maskcount?: number | null,
  maxsentimentint?: number | null,
  order?: string | null,
  selectioncount?: number | null,
  type: CarFaceType,
};

export type CreateCarFaceMutation = {
  createCarFace?:  {
    __typename: "CarFace",
    alignment?: string | null,
    damagecount?: number | null,
    event: string,
    imagetotal?: number | null,
    maskcount?: number | null,
    maxsentimentint?: number | null,
    order?: string | null,
    selectioncount?: number | null,
    type: CarFaceType,
  } | null,
};

export type CreateCustomerMutationVariables = {
  email: string,
  event: string,
  phone?: string | null,
  status?: string | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    datetime?: string | null,
    email?: string | null,
    event: string,
    phone?: string | null,
    status?: string | null,
  } | null,
};

export type CreateDebugMutationVariables = {
  datetime: string,
  debugtype: DebugType,
  msg: string,
};

export type CreateDebugMutation = {
  createDebug?:  {
    __typename: "Debug",
    datetime: string,
    debugtype?: DebugType | null,
    id: string,
    msg?: string | null,
  } | null,
};

export type CreateDevCrowdusersMutationVariables = {
  input: CreateDevCrowdusersInput,
};

export type CreateDevCrowdusersMutation = {
  createDevCrowdusers?:  {
    __typename: "DevCrowdusers",
    email: string,
    uploaded?: number | null,
  } | null,
};

export type CreateEventMutationVariables = {
  cid?: number | null,
  completed?: boolean | null,
  conditionProperties?: string | null,
  customerfueled?: boolean | null,
  custompipeline?: number | null,
  datetime: string,
  distancetravelled?: number | null,
  eventtype: EventType,
  expectedfaces?: number | null,
  fuel?: string | null,
  id?: string | null,
  imageTypes?: number | null,
  imagetotal?: number | null,
  managerreviewed?: boolean | null,
  observations?: string | null,
  odometer?: number | null,
  relatedcheckoutevent?: string | null,
  rentalagreement?: string | null,
  requestedalignment?: number | null,
  sitename: string,
  statelicense: string,
  user?: string | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    cid: number,
    completed?: boolean | null,
    conditionProperties?: string | null,
    customerfueled?: boolean | null,
    custommetadata?: string | null,
    // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
    // # damage detection, 2 = TBD, 4 = TBD
    custompipeline?: number | null,
    datetime: string,
    distancetravelled?: number | null,
    eventtype: EventType,
    expectedfaces?: number | null,
    fuel?: string | null,
    id: string,
    imageTypes?: number | null,
    imagetotal?: number | null,
    invertedsentimentdatetime?: string | null,
    managerreviewed?: boolean | null,
    notificationTypes?: number | null,
    // The following support notifications. Initially for the API
    notificationURL?: string | null,
    observations?: string | null,
    odometer?: number | null,
    relatedcheckoutevent?: string | null,
    rentalagreement?: string | null,
    // Following is a bitcode where 0 means no alignment on any side and 15 all sides
    requestedalignment?: number | null,
    reviewstatus?: EventReviewStatus | null,
    risk?: Risk | null,
    sentiment?: DamageSentiment | null,
    sentimentdatetime?: string | null,
    signature?: string | null,
    sitename: string,
    statelicense: string,
    user?: string | null,
  } | null,
};

export type CreateImageMutationVariables = {
  event: string,
  eventType: EventType,
  faceposition?: number | null,
  facetype?: CarFaceType | null,
  height?: number | null,
  istheredamage: number,
  latitude?: number | null,
  longitude?: number | null,
  position: number,
  processingstate?: string | null,
  s3path: string,
  totalCount: number,
  width?: number | null,
};

export type CreateImageMutation = {
  createImage?:  {
    __typename: "Image",
    event: string,
    eventtype?: EventType | null,
    faceposition?: number | null,
    facetype?: CarFaceType | null,
    height?: number | null,
    id: string,
    // set by users manually
    istheredamage?: number | null,
    position?: number | null,
    processingstate?: string | null,
    s3path: string,
    selected?: boolean | null,
    totalCount?: number | null,
    width?: number | null,
  } | null,
};

export type CreateImageDamageMutationVariables = {
  annotationsummary?: number | null,
  cid?: number | null,
  event: string,
  image: string,
  json: string,
};

export type CreateImageDamageMutation = {
  createImageDamage?:  {
    __typename: "ImageDamage",
    annotation?: string | null,
    annotationsummary?: number | null,
    cid: number,
    event?: string | null,
    image: string,
    json?: string | null,
  } | null,
};

export type CreateImageMaskMutationVariables = {
  image: string,
  json: string,
  masks3path?: string | null,
  event?: string | null,
};

export type CreateImageMaskMutation = {
  createImageMask?:  {
    __typename: "ImageMask",
    image: string,
    json?: string | null,
    masks3path?: string | null,
    event?: string | null,
  } | null,
};

export type CreateRepDamageMutationVariables = {
  action?: DamageAction | null,
  additionalinfo: string,
  part?: string | null,
  boundingbox: string,
  cid: number,
  confidence: number,
  datetime: string,
  duplicate: DamageDuplication,
  event: string,
  face: CarFaceType,
  image: string,
  position: number,
  score?: number | null,
  sentiment?: DamageSentiment | null,
  sentimentnum?: number | null,
  severity?: DamageSeverity | null,
  source: DamageSource,
  statelicense: string,
  type?: DamageType | null,
};

export type CreateRepDamageMutation = {
  createRepDamage?:  {
    __typename: "RepDamage",
    action?: DamageAction | null,
    additionalinfo: string,
    part?: string | null,
    boundingbox: string,
    cid: number,
    confidence: number,
    datetime: string,
    duplicate: DamageDuplication,
    event: string,
    face: CarFaceType,
    id: string,
    image: string,
    preexistingcid_asset?: string | null,
    position: number,
    relatedregion?: string | null,
    score: number,
    sentiment?: DamageSentiment | null,
    sentimentnum?: number | null,
    severity?: DamageSeverity | null,
    source: DamageSource,
    statelicense: string,
    type?: DamageType | null,
  } | null,
};

export type CreateReportMutationVariables = {
  cid: number,
  comparedevent: string,
  items?: string | null,
  reportdatetime?: string | null,
  reportevent: string,
  status?: number | null,
  text?: string | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    cid: number,
    comparedevent: string,
    id: string,
    items?: string | null,
    reportdatetime: string,
    reportevent: string,
    status: number,
    text?: string | null,
  } | null,
};

export type CreateSiteMutationVariables = {
  cid: number,
  company_name?: string | null,
  location?: string | null,
  sitename: string,
};

export type CreateSiteMutation = {
  createSite?:  {
    __typename: "Site",
    brand?: string | null,
    cid: number,
    company_name?: string | null,
    country?: string | null,
    district?: string | null,
    location?: string | null,
    region?: string | null,
    site?: string | null,
    siteid?: number | null,
    sitename: string,
    state?: string | null,
    statusrequest?: number | null,
  } | null,
};

export type CreateUserMutationVariables = {
  username: string,
  userpwd: string,
  usertype: number,
  sites: string,
  defaultemail?: string | null,
};

export type CreateUserMutation = {
  createUser?: string | null,
};

export type DeleteCustomerMutationVariables = {
  event: string,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    datetime?: string | null,
    email?: string | null,
    event: string,
    phone?: string | null,
    status?: string | null,
  } | null,
};

export type DeleteDevCrowdusersMutationVariables = {
  input: DeleteDevCrowdusersInput,
};

export type DeleteDevCrowdusersMutation = {
  deleteDevCrowdusers?:  {
    __typename: "DevCrowdusers",
    email: string,
    uploaded?: number | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  id: string,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    cid: number,
    completed?: boolean | null,
    conditionProperties?: string | null,
    customerfueled?: boolean | null,
    custommetadata?: string | null,
    // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
    // # damage detection, 2 = TBD, 4 = TBD
    custompipeline?: number | null,
    datetime: string,
    distancetravelled?: number | null,
    eventtype: EventType,
    expectedfaces?: number | null,
    fuel?: string | null,
    id: string,
    imageTypes?: number | null,
    imagetotal?: number | null,
    invertedsentimentdatetime?: string | null,
    managerreviewed?: boolean | null,
    notificationTypes?: number | null,
    // The following support notifications. Initially for the API
    notificationURL?: string | null,
    observations?: string | null,
    odometer?: number | null,
    relatedcheckoutevent?: string | null,
    rentalagreement?: string | null,
    // Following is a bitcode where 0 means no alignment on any side and 15 all sides
    requestedalignment?: number | null,
    reviewstatus?: EventReviewStatus | null,
    risk?: Risk | null,
    sentiment?: DamageSentiment | null,
    sentimentdatetime?: string | null,
    signature?: string | null,
    sitename: string,
    statelicense: string,
    user?: string | null,
  } | null,
};

export type DeleteImageMutationVariables = {
  id: string,
};

export type DeleteImageMutation = {
  deleteImage?:  {
    __typename: "Image",
    event: string,
    eventtype?: EventType | null,
    faceposition?: number | null,
    facetype?: CarFaceType | null,
    height?: number | null,
    id: string,
    // set by users manually
    istheredamage?: number | null,
    position?: number | null,
    processingstate?: string | null,
    s3path: string,
    selected?: boolean | null,
    totalCount?: number | null,
    width?: number | null,
  } | null,
};

export type DeleteImageDamageMutationVariables = {
  image: string,
};

export type DeleteImageDamageMutation = {
  deleteImageDamage?:  {
    __typename: "ImageDamage",
    annotation?: string | null,
    annotationsummary?: number | null,
    cid: number,
    event?: string | null,
    image: string,
    json?: string | null,
  } | null,
};

export type DeleteImageMaskMutationVariables = {
  image: string,
};

export type DeleteImageMaskMutation = {
  deleteImageMask?:  {
    __typename: "ImageMask",
    image: string,
    json?: string | null,
    masks3path?: string | null,
    event?: string | null,
  } | null,
};

export type DeleteRepDamageMutationVariables = {
  id: string,
};

export type DeleteRepDamageMutation = {
  deleteRepDamage?:  {
    __typename: "RepDamage",
    action?: DamageAction | null,
    additionalinfo: string,
    part?: string | null,
    boundingbox: string,
    cid: number,
    confidence: number,
    datetime: string,
    duplicate: DamageDuplication,
    event: string,
    face: CarFaceType,
    id: string,
    image: string,
    preexistingcid_asset?: string | null,
    position: number,
    relatedregion?: string | null,
    score: number,
    sentiment?: DamageSentiment | null,
    sentimentnum?: number | null,
    severity?: DamageSeverity | null,
    source: DamageSource,
    statelicense: string,
    type?: DamageType | null,
  } | null,
};

export type IncrementCarFaceDamageCountMutationVariables = {
  event: string,
  type: CarFaceType,
};

export type IncrementCarFaceDamageCountMutation = {
  incrementCarFaceDamageCount?:  {
    __typename: "CarFace",
    alignment?: string | null,
    damagecount?: number | null,
    event: string,
    imagetotal?: number | null,
    maskcount?: number | null,
    maxsentimentint?: number | null,
    order?: string | null,
    selectioncount?: number | null,
    type: CarFaceType,
  } | null,
};

export type IncrementCarFaceMaskCountMutationVariables = {
  event: string,
  type: CarFaceType,
};

export type IncrementCarFaceMaskCountMutation = {
  incrementCarFaceMaskCount?:  {
    __typename: "CarFace",
    alignment?: string | null,
    damagecount?: number | null,
    event: string,
    imagetotal?: number | null,
    maskcount?: number | null,
    maxsentimentint?: number | null,
    order?: string | null,
    selectioncount?: number | null,
    type: CarFaceType,
  } | null,
};

export type UpdateCarMutationVariables = {
  carmodelinfo?: string | null,
  cartype?: number | null,
  cid: number,
  colour?: string | null,
  conditionstatus?: AssetConditionStatus | null,
  creation?: string | null,
  event?: string | null,
  heighttype?: string | null,
  lastseen?: string | null,
  model?: string | null,
  odometer?: number | null,
  rentalagreement?: string | null,
  rentalstatus?: AssetRentalStatus | null,
  secondaryid?: string | null,
  sitename?: string | null,
  statelicense: string,
};

export type UpdateCarMutation = {
  updateCar?:  {
    __typename: "Car",
    carmodelinfo?: string | null,
    cartype?: number | null,
    cid: number,
    colour?: string | null,
    conditionstatus?: AssetConditionStatus | null,
    creation?: string | null,
    event?: string | null,
    heighttype?: string | null,
    lastseen?: string | null,
    model?: string | null,
    odometer?: number | null,
    rentalagreement?: string | null,
    rentalstatus?: AssetRentalStatus | null,
    secondaryid?: string | null,
    sitename?: string | null,
    statelicense: string,
  } | null,
};

export type UpdateCarFaceMutationVariables = {
  alignment?: string | null,
  event: string,
  imagetotal?: number | null,
  maxsentimentint?: number | null,
  order?: string | null,
  selectioncount?: number | null,
  damagecount?: number | null,
  maskcount?: number | null,
  type: CarFaceType,
};

export type UpdateCarFaceMutation = {
  updateCarFace?:  {
    __typename: "CarFace",
    alignment?: string | null,
    damagecount?: number | null,
    event: string,
    imagetotal?: number | null,
    maskcount?: number | null,
    maxsentimentint?: number | null,
    order?: string | null,
    selectioncount?: number | null,
    type: CarFaceType,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  datetime?: string | null,
  email?: string | null,
  event: string,
  phone?: string | null,
  status?: string | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    datetime?: string | null,
    email?: string | null,
    event: string,
    phone?: string | null,
    status?: string | null,
  } | null,
};

export type UpdateDevCrowdusersMutationVariables = {
  input: UpdateDevCrowdusersInput,
};

export type UpdateDevCrowdusersMutation = {
  updateDevCrowdusers?:  {
    __typename: "DevCrowdusers",
    email: string,
    uploaded?: number | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  completed?: boolean | null,
  conditionProperties?: string | null,
  customerfueled?: boolean | null,
  custommetadata?: string | null,
  datetime?: string | null,
  distancetravelled?: number | null,
  eventtype?: EventType | null,
  expectedfaces?: number | null,
  fuel?: string | null,
  id: string,
  imageTypes?: number | null,
  imagetotal?: number | null,
  invertedsentimentdatetime?: string | null,
  managerreviewed?: boolean | null,
  observations?: string | null,
  odometer?: number | null,
  relatedcheckoutevent?: string | null,
  rentalagreement?: string | null,
  reviewstatus?: EventReviewStatus | null,
  risk?: Risk | null,
  sentiment?: DamageSentiment | null,
  sentimentdatetime?: string | null,
  signature?: string | null,
  sitename?: string | null,
  statelicense?: string | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    cid: number,
    completed?: boolean | null,
    conditionProperties?: string | null,
    customerfueled?: boolean | null,
    custommetadata?: string | null,
    // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
    // # damage detection, 2 = TBD, 4 = TBD
    custompipeline?: number | null,
    datetime: string,
    distancetravelled?: number | null,
    eventtype: EventType,
    expectedfaces?: number | null,
    fuel?: string | null,
    id: string,
    imageTypes?: number | null,
    imagetotal?: number | null,
    invertedsentimentdatetime?: string | null,
    managerreviewed?: boolean | null,
    notificationTypes?: number | null,
    // The following support notifications. Initially for the API
    notificationURL?: string | null,
    observations?: string | null,
    odometer?: number | null,
    relatedcheckoutevent?: string | null,
    rentalagreement?: string | null,
    // Following is a bitcode where 0 means no alignment on any side and 15 all sides
    requestedalignment?: number | null,
    reviewstatus?: EventReviewStatus | null,
    risk?: Risk | null,
    sentiment?: DamageSentiment | null,
    sentimentdatetime?: string | null,
    signature?: string | null,
    sitename: string,
    statelicense: string,
    user?: string | null,
  } | null,
};

export type UpdateEventNotNullMutationVariables = {
  completed?: boolean | null,
  conditionProperties?: string | null,
  customerfueled?: boolean | null,
  custommetadata?: string | null,
  datetime?: string | null,
  distancetravelled?: number | null,
  eventtype?: EventType | null,
  fuel?: string | null,
  id: string,
  imageTypes?: number | null,
  imagetotal?: number | null,
  managerreviewed?: boolean | null,
  observations?: string | null,
  odometer?: number | null,
  relatedcheckoutevent?: string | null,
  rentalagreement?: string | null,
  reviewstatus?: EventReviewStatus | null,
  risk?: Risk | null,
  sentiment?: DamageSentiment | null,
  signature?: string | null,
  sitename?: string | null,
  statelicense?: string | null,
};

export type UpdateEventNotNullMutation = {
  updateEventNotNull?:  {
    __typename: "Event",
    cid: number,
    completed?: boolean | null,
    conditionProperties?: string | null,
    customerfueled?: boolean | null,
    custommetadata?: string | null,
    // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
    // # damage detection, 2 = TBD, 4 = TBD
    custompipeline?: number | null,
    datetime: string,
    distancetravelled?: number | null,
    eventtype: EventType,
    expectedfaces?: number | null,
    fuel?: string | null,
    id: string,
    imageTypes?: number | null,
    imagetotal?: number | null,
    invertedsentimentdatetime?: string | null,
    managerreviewed?: boolean | null,
    notificationTypes?: number | null,
    // The following support notifications. Initially for the API
    notificationURL?: string | null,
    observations?: string | null,
    odometer?: number | null,
    relatedcheckoutevent?: string | null,
    rentalagreement?: string | null,
    // Following is a bitcode where 0 means no alignment on any side and 15 all sides
    requestedalignment?: number | null,
    reviewstatus?: EventReviewStatus | null,
    risk?: Risk | null,
    sentiment?: DamageSentiment | null,
    sentimentdatetime?: string | null,
    signature?: string | null,
    sitename: string,
    statelicense: string,
    user?: string | null,
  } | null,
};

export type UpdateImageMutationVariables = {
  id: string,
  istheredamage?: number | null,
  processingstate?: string | null,
  selected?: boolean | null,
};

export type UpdateImageMutation = {
  updateImage?:  {
    __typename: "Image",
    event: string,
    eventtype?: EventType | null,
    faceposition?: number | null,
    facetype?: CarFaceType | null,
    height?: number | null,
    id: string,
    // set by users manually
    istheredamage?: number | null,
    position?: number | null,
    processingstate?: string | null,
    s3path: string,
    selected?: boolean | null,
    totalCount?: number | null,
    width?: number | null,
  } | null,
};

export type UpdateImageDamageMutationVariables = {
  annotation?: string | null,
  annotationsummary?: number | null,
  image: string,
  json?: string | null,
};

export type UpdateImageDamageMutation = {
  updateImageDamage?:  {
    __typename: "ImageDamage",
    annotation?: string | null,
    annotationsummary?: number | null,
    cid: number,
    event?: string | null,
    image: string,
    json?: string | null,
  } | null,
};

export type UpdateImageDamage2ObsoleteMutationVariables = {
  annotation?: string | null,
  annotationsummary?: number | null,
  cid?: number | null,
  event?: string | null,
  image: string,
  json?: string | null,
};

export type UpdateImageDamage2ObsoleteMutation = {
  updateImageDamage2Obsolete?:  {
    __typename: "ImageDamage",
    annotation?: string | null,
    annotationsummary?: number | null,
    cid: number,
    event?: string | null,
    image: string,
    json?: string | null,
  } | null,
};

export type UpdateRepDamageMutationVariables = {
  action?: DamageAction | null,
  additionalinfo?: string | null,
  part?: string | null,
  boundingbox?: string | null,
  confidence?: number | null,
  duplicate?: DamageDuplication | null,
  id: string,
  relatedregion?: string | null,
  score?: number | null,
  sentiment?: DamageSentiment | null,
  sentimentnum?: number | null,
  severity?: DamageSeverity | null,
  source?: DamageSource | null,
  type?: DamageType | null,
};

export type UpdateRepDamageMutation = {
  updateRepDamage?:  {
    __typename: "RepDamage",
    action?: DamageAction | null,
    additionalinfo: string,
    part?: string | null,
    boundingbox: string,
    cid: number,
    confidence: number,
    datetime: string,
    duplicate: DamageDuplication,
    event: string,
    face: CarFaceType,
    id: string,
    image: string,
    preexistingcid_asset?: string | null,
    position: number,
    relatedregion?: string | null,
    score: number,
    sentiment?: DamageSentiment | null,
    sentimentnum?: number | null,
    severity?: DamageSeverity | null,
    source: DamageSource,
    statelicense: string,
    type?: DamageType | null,
  } | null,
};

export type UpdateReportMutationVariables = {
  id: string,
  items?: string | null,
  reportdatetime?: string | null,
  status?: number | null,
  text?: string | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    cid: number,
    comparedevent: string,
    id: string,
    items?: string | null,
    reportdatetime: string,
    reportevent: string,
    status: number,
    text?: string | null,
  } | null,
};

export type UpdateSiteMutationVariables = {
  contact?: string | null,
  sitename: string,
  statusrequest?: number | null,
};

export type UpdateSiteMutation = {
  updateSite?:  {
    __typename: "Site",
    brand?: string | null,
    cid: number,
    company_name?: string | null,
    country?: string | null,
    district?: string | null,
    location?: string | null,
    region?: string | null,
    site?: string | null,
    siteid?: number | null,
    sitename: string,
    state?: string | null,
    statusrequest?: number | null,
  } | null,
};

export type SetPreexistingDamageMutationVariables = {
  id: string,
  cid: number,
  statelicense: string,
};

export type SetPreexistingDamageMutation = {
  setPreexistingDamage?:  {
    __typename: "RepDamage",
    action?: DamageAction | null,
    additionalinfo: string,
    part?: string | null,
    boundingbox: string,
    cid: number,
    confidence: number,
    datetime: string,
    duplicate: DamageDuplication,
    event: string,
    face: CarFaceType,
    id: string,
    image: string,
    preexistingcid_asset?: string | null,
    position: number,
    relatedregion?: string | null,
    score: number,
    sentiment?: DamageSentiment | null,
    sentimentnum?: number | null,
    severity?: DamageSeverity | null,
    source: DamageSource,
    statelicense: string,
    type?: DamageType | null,
  } | null,
};

export type ResetPreexistingDamageMutationVariables = {
  id: string,
};

export type ResetPreexistingDamageMutation = {
  resetPreexistingDamage?:  {
    __typename: "RepDamage",
    action?: DamageAction | null,
    additionalinfo: string,
    part?: string | null,
    boundingbox: string,
    cid: number,
    confidence: number,
    datetime: string,
    duplicate: DamageDuplication,
    event: string,
    face: CarFaceType,
    id: string,
    image: string,
    preexistingcid_asset?: string | null,
    position: number,
    relatedregion?: string | null,
    score: number,
    sentiment?: DamageSentiment | null,
    sentimentnum?: number | null,
    severity?: DamageSeverity | null,
    source: DamageSource,
    statelicense: string,
    type?: DamageType | null,
  } | null,
};

export type GetClaimStatsQuery = {
  getClaimStats?:  {
    __typename: "claim_stats",
    cnt_claims?: number | null,
    cnt_requested?: number | null,
    cnt_accepted?: number | null,
    cnt_received?: number | null,
  } | null,
};

export type SimilarDamagesByPageQueryVariables = {
  nextToken?: number | null,
  id?: number | null,
};

export type SimilarDamagesByPageQuery = {
  similarDamagesByPage?:  {
    __typename: "PaginatedDamage",
    nextToken?: string | null,
    damages?:  Array< {
      __typename: "damage_info",
      image_url?: string | null,
      description?: string | null,
      location?: string | null,
      type?: string | null,
      car_reg?: string | null,
      car_id?: number | null,
      damage_id?: number | null,
    } | null > | null,
  } | null,
};

export type GetQuoteInfoForIdQueryVariables = {
  id?: number | null,
};

export type GetQuoteInfoForIdQuery = {
  getQuoteInfoForId?:  {
    __typename: "quote_info",
    amount?: number | null,
    id: number,
    quote_num?: string | null,
    type?: string | null,
    Claim_id?: number | null,
    Claim_Repairer_id?: number | null,
  } | null,
};

export type GetConfidenceLevelQueryVariables = {
  current_confidence?: number | null,
  query_carid?: number | null,
  selected_carid?: number | null,
};

export type GetConfidenceLevelQuery = {
  getConfidenceLevel?:  {
    __typename: "ClaimConfidence",
    confidence?: number | null,
  } | null,
};

export type LookupQuery = {
  lookup?:  Array< {
    __typename: "lookup_info",
    state?: number | null,
    description?: string | null,
  } | null > | null,
};

export type AllCarEventsQueryVariables = {
  count?: number | null,
  nextToken?: string | null,
  statelicense: string,
};

export type AllCarEventsQuery = {
  allCarEvents?:  {
    __typename: "PaginatedEvent",
    events?:  Array< {
      __typename: "Event",
      cid: number,
      completed?: boolean | null,
      conditionProperties?: string | null,
      customerfueled?: boolean | null,
      custommetadata?: string | null,
      // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
      // # damage detection, 2 = TBD, 4 = TBD
      custompipeline?: number | null,
      datetime: string,
      distancetravelled?: number | null,
      eventtype: EventType,
      expectedfaces?: number | null,
      fuel?: string | null,
      id: string,
      imageTypes?: number | null,
      imagetotal?: number | null,
      invertedsentimentdatetime?: string | null,
      managerreviewed?: boolean | null,
      notificationTypes?: number | null,
      // The following support notifications. Initially for the API
      notificationURL?: string | null,
      observations?: string | null,
      odometer?: number | null,
      relatedcheckoutevent?: string | null,
      rentalagreement?: string | null,
      // Following is a bitcode where 0 means no alignment on any side and 15 all sides
      requestedalignment?: number | null,
      reviewstatus?: EventReviewStatus | null,
      risk?: Risk | null,
      sentiment?: DamageSentiment | null,
      sentimentdatetime?: string | null,
      signature?: string | null,
      sitename: string,
      statelicense: string,
      user?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type AllCarsQueryVariables = {
  cid?: number | null,
  count?: number | null,
  nextToken?: string | null,
};

export type AllCarsQuery = {
  allCars?:  {
    __typename: "PaginatedCar",
    cars?:  Array< {
      __typename: "Car",
      carmodelinfo?: string | null,
      cartype?: number | null,
      cid: number,
      colour?: string | null,
      conditionstatus?: AssetConditionStatus | null,
      creation?: string | null,
      event?: string | null,
      heighttype?: string | null,
      lastseen?: string | null,
      model?: string | null,
      odometer?: number | null,
      rentalagreement?: string | null,
      rentalstatus?: AssetRentalStatus | null,
      secondaryid?: string | null,
      sitename?: string | null,
      statelicense: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type AllClaimsByPageQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  filter?: claimFilterInput | null,
};

export type AllClaimsByPageQuery = {
  allClaimsByPage?:  {
    __typename: "PaginatedClaim",
    claims?:  Array< {
      __typename: "claim_info",
      car_reg?: string | null,
      claim_num?: string | null,
      description?: string | null,
      model?: string | null,
      policy_num?: string | null,
      recv_date?: string | null,
      state?: number | null,
      year?: number | null,
      id?: number | null,
      initial_amount?: number | null,
      final_amount?: number | null,
      age?: number | null,
      analyst?: string | null,
      notes?: string | null,
      location?: string | null,
    } | null > | null,
    nextToken?: string | null,
    count?: number | null,
    limit?: number | null,
    filter?: string | null,
  } | null,
};

export type AllDamageAnnotationsByEventQueryVariables = {
  count?: number | null,
  event: string,
  minannotationsummary: number,
  nextToken?: string | null,
};

export type AllDamageAnnotationsByEventQuery = {
  allDamageAnnotationsByEvent?:  {
    __typename: "PaginatedImageDamage",
    imageDamages:  Array< {
      __typename: "ImageDamage",
      annotation?: string | null,
      annotationsummary?: number | null,
      cid: number,
      event?: string | null,
      image: string,
      json?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type AllEventsQueryVariables = {
  cid?: number | null,
  count?: number | null,
  nextToken?: string | null,
};

export type AllEventsQuery = {
  allEvents?:  {
    __typename: "PaginatedEvent",
    events?:  Array< {
      __typename: "Event",
      cid: number,
      completed?: boolean | null,
      conditionProperties?: string | null,
      customerfueled?: boolean | null,
      custommetadata?: string | null,
      // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
      // # damage detection, 2 = TBD, 4 = TBD
      custompipeline?: number | null,
      datetime: string,
      distancetravelled?: number | null,
      eventtype: EventType,
      expectedfaces?: number | null,
      fuel?: string | null,
      id: string,
      imageTypes?: number | null,
      imagetotal?: number | null,
      invertedsentimentdatetime?: string | null,
      managerreviewed?: boolean | null,
      notificationTypes?: number | null,
      // The following support notifications. Initially for the API
      notificationURL?: string | null,
      observations?: string | null,
      odometer?: number | null,
      relatedcheckoutevent?: string | null,
      rentalagreement?: string | null,
      // Following is a bitcode where 0 means no alignment on any side and 15 all sides
      requestedalignment?: number | null,
      reviewstatus?: EventReviewStatus | null,
      risk?: Risk | null,
      sentiment?: DamageSentiment | null,
      sentimentdatetime?: string | null,
      signature?: string | null,
      sitename: string,
      statelicense: string,
      user?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type AllEventsBySiteQueryVariables = {
  count?: number | null,
  eventtype: EventType,
  nextToken?: string | null,
  sitename: string,
};

export type AllEventsBySiteQuery = {
  allEventsBySite?:  {
    __typename: "PaginatedEvent",
    events?:  Array< {
      __typename: "Event",
      cid: number,
      completed?: boolean | null,
      conditionProperties?: string | null,
      customerfueled?: boolean | null,
      custommetadata?: string | null,
      // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
      // # damage detection, 2 = TBD, 4 = TBD
      custompipeline?: number | null,
      datetime: string,
      distancetravelled?: number | null,
      eventtype: EventType,
      expectedfaces?: number | null,
      fuel?: string | null,
      id: string,
      imageTypes?: number | null,
      imagetotal?: number | null,
      invertedsentimentdatetime?: string | null,
      managerreviewed?: boolean | null,
      notificationTypes?: number | null,
      // The following support notifications. Initially for the API
      notificationURL?: string | null,
      observations?: string | null,
      odometer?: number | null,
      relatedcheckoutevent?: string | null,
      rentalagreement?: string | null,
      // Following is a bitcode where 0 means no alignment on any side and 15 all sides
      requestedalignment?: number | null,
      reviewstatus?: EventReviewStatus | null,
      risk?: Risk | null,
      sentiment?: DamageSentiment | null,
      sentimentdatetime?: string | null,
      signature?: string | null,
      sitename: string,
      statelicense: string,
      user?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type AllEventsByTypeQueryVariables = {
  cid: number,
  count?: number | null,
  eventtype: EventType,
  nextToken?: string | null,
  sitename?: string | null,
};

export type AllEventsByTypeQuery = {
  allEventsByType?:  {
    __typename: "PaginatedEvent",
    events?:  Array< {
      __typename: "Event",
      cid: number,
      completed?: boolean | null,
      conditionProperties?: string | null,
      customerfueled?: boolean | null,
      custommetadata?: string | null,
      // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
      // # damage detection, 2 = TBD, 4 = TBD
      custompipeline?: number | null,
      datetime: string,
      distancetravelled?: number | null,
      eventtype: EventType,
      expectedfaces?: number | null,
      fuel?: string | null,
      id: string,
      imageTypes?: number | null,
      imagetotal?: number | null,
      invertedsentimentdatetime?: string | null,
      managerreviewed?: boolean | null,
      notificationTypes?: number | null,
      // The following support notifications. Initially for the API
      notificationURL?: string | null,
      observations?: string | null,
      odometer?: number | null,
      relatedcheckoutevent?: string | null,
      rentalagreement?: string | null,
      // Following is a bitcode where 0 means no alignment on any side and 15 all sides
      requestedalignment?: number | null,
      reviewstatus?: EventReviewStatus | null,
      risk?: Risk | null,
      sentiment?: DamageSentiment | null,
      sentimentdatetime?: string | null,
      signature?: string | null,
      sitename: string,
      statelicense: string,
      user?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type AllEventsByTypeFilteredQueryVariables = {
  cid: number,
  count?: number | null,
  eventtype: EventType,
  filter?: EventFilterInput | null,
  nextToken?: string | null,
  sitename?: string | null,
};

export type AllEventsByTypeFilteredQuery = {
  allEventsByTypeFiltered?:  {
    __typename: "PaginatedEvent",
    events?:  Array< {
      __typename: "Event",
      cid: number,
      completed?: boolean | null,
      conditionProperties?: string | null,
      customerfueled?: boolean | null,
      custommetadata?: string | null,
      // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
      // # damage detection, 2 = TBD, 4 = TBD
      custompipeline?: number | null,
      datetime: string,
      distancetravelled?: number | null,
      eventtype: EventType,
      expectedfaces?: number | null,
      fuel?: string | null,
      id: string,
      imageTypes?: number | null,
      imagetotal?: number | null,
      invertedsentimentdatetime?: string | null,
      managerreviewed?: boolean | null,
      notificationTypes?: number | null,
      // The following support notifications. Initially for the API
      notificationURL?: string | null,
      observations?: string | null,
      odometer?: number | null,
      relatedcheckoutevent?: string | null,
      rentalagreement?: string | null,
      // Following is a bitcode where 0 means no alignment on any side and 15 all sides
      requestedalignment?: number | null,
      reviewstatus?: EventReviewStatus | null,
      risk?: Risk | null,
      sentiment?: DamageSentiment | null,
      sentimentdatetime?: string | null,
      signature?: string | null,
      sitename: string,
      statelicense: string,
      user?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type AllNewDamageAnnotationsQueryVariables = {
  cid?: number | null,
  count?: number | null,
  nextToken?: string | null,
};

export type AllNewDamageAnnotationsQuery = {
  allNewDamageAnnotations?:  {
    __typename: "PaginatedImageDamage",
    imageDamages:  Array< {
      __typename: "ImageDamage",
      annotation?: string | null,
      annotationsummary?: number | null,
      cid: number,
      event?: string | null,
      image: string,
      json?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type AllSitesQueryVariables = {
  cid?: number | null,
  count?: number | null,
  nextToken?: string | null,
};

export type AllSitesQuery = {
  allSites?:  {
    __typename: "PaginatedSite",
    nextToken?: string | null,
    sites:  Array< {
      __typename: "Site",
      brand?: string | null,
      cid: number,
      company_name?: string | null,
      country?: string | null,
      district?: string | null,
      location?: string | null,
      region?: string | null,
      site?: string | null,
      siteid?: number | null,
      sitename: string,
      state?: string | null,
      statusrequest?: number | null,
    } >,
  } | null,
};

export type AppSettingsByCidQueryVariables = {
  account?: string | null,
  cid: number,
  sitename?: string | null,
};

export type AppSettingsByCidQuery = {
  appSettingsByCid?:  {
    __typename: "AppSettings",
    account?: string | null,
    apppreference?: string | null,
    appworkflow?: string | null,
    cid: number,
    sitename?: string | null,
  } | null,
};

export type AssetDashboardQueryQueryVariables = {
  cid: number,
  filter?: AssetDashboardFilterInput | null,
};

export type AssetDashboardQueryQuery = {
  assetDashboardQuery?:  {
    __typename: "AssetDashboard",
    checkedin?: number | null,
    checkedout?: number | null,
    readyline?: number | null,
    totalassets?: number | null,
    unavailable?: number | null,
  } | null,
};

export type AssetItemQueryQueryVariables = {
  cid: number,
  statelicense: string,
};

export type AssetItemQueryQuery = {
  assetItemQuery?:  {
    __typename: "AssetStatus",
    cartype?: number | null,
    cid: number,
    conditionProperties?: string | null,
    conditionstatus?: AssetConditionStatus | null,
    creation?: string | null,
    event?: string | null,
    lastseen?: string | null,
    odometer?: number | null,
    rentalagreement?: string | null,
    rentalstatus?: AssetRentalStatus | null,
    sitename?: string | null,
    statelicense: string,
  } | null,
};

export type AssetListQueryQueryVariables = {
  accumulate?: boolean | null,
  cid: number,
  count?: number | null,
  filter?: AssetStatusFilterInput | null,
  nextToken?: string | null,
};

export type AssetListQueryQuery = {
  assetListQuery?:  {
    __typename: "PaginatedAssetStatus",
    assetStatuses?:  Array< {
      __typename: "AssetStatus",
      cartype?: number | null,
      cid: number,
      conditionProperties?: string | null,
      conditionstatus?: AssetConditionStatus | null,
      creation?: string | null,
      event?: string | null,
      lastseen?: string | null,
      odometer?: number | null,
      rentalagreement?: string | null,
      rentalstatus?: AssetRentalStatus | null,
      sitename?: string | null,
      statelicense: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CarAlignmentByEventAndTypeQueryVariables = {
  event: string,
  ftype: CarFaceType,
};

export type CarAlignmentByEventAndTypeQuery = {
  carAlignmentByEventAndType?:  Array< {
    __typename: "CarAlignment",
    comparedevent: string,
    creationdatetime: string,
    event: string,
    ftype: CarFaceType,
    isRelatedCheckoutEvent: boolean,
    jsonstr: string,
    order: string,
  } | null > | null,
};

export type CarAlignmentByEventsQueryVariables = {
  comparedevent: string,
  event: string,
  ftype: CarFaceType,
};

export type CarAlignmentByEventsQuery = {
  carAlignmentByEvents?:  Array< {
    __typename: "CarAlignment",
    comparedevent: string,
    creationdatetime: string,
    event: string,
    ftype: CarFaceType,
    isRelatedCheckoutEvent: boolean,
    jsonstr: string,
    order: string,
  } | null > | null,
};

export type CarByIdQueryVariables = {
  statelicense: string,
};

export type CarByIdQuery = {
  carById?:  {
    __typename: "Car",
    carmodelinfo?: string | null,
    cartype?: number | null,
    cid: number,
    colour?: string | null,
    conditionstatus?: AssetConditionStatus | null,
    creation?: string | null,
    event?: string | null,
    heighttype?: string | null,
    lastseen?: string | null,
    model?: string | null,
    odometer?: number | null,
    rentalagreement?: string | null,
    rentalstatus?: AssetRentalStatus | null,
    secondaryid?: string | null,
    sitename?: string | null,
    statelicense: string,
  } | null,
};

export type CarByIdAndCidQueryVariables = {
  cid: number,
  statelicense: string,
};

export type CarByIdAndCidQuery = {
  carByIdAndCid?:  {
    __typename: "Car",
    carmodelinfo?: string | null,
    cartype?: number | null,
    cid: number,
    colour?: string | null,
    conditionstatus?: AssetConditionStatus | null,
    creation?: string | null,
    event?: string | null,
    heighttype?: string | null,
    lastseen?: string | null,
    model?: string | null,
    odometer?: number | null,
    rentalagreement?: string | null,
    rentalstatus?: AssetRentalStatus | null,
    secondaryid?: string | null,
    sitename?: string | null,
    statelicense: string,
  } | null,
};

export type CarFaceByEventAndTypeQueryVariables = {
  event: string,
  type: CarFaceType,
};

export type CarFaceByEventAndTypeQuery = {
  carFaceByEventAndType?:  {
    __typename: "CarFace",
    alignment?: string | null,
    damagecount?: number | null,
    event: string,
    imagetotal?: number | null,
    maskcount?: number | null,
    maxsentimentint?: number | null,
    order?: string | null,
    selectioncount?: number | null,
    type: CarFaceType,
  } | null,
};

export type ClaimByNumQueryVariables = {
  claim_num: string,
};

export type ClaimByNumQuery = {
  claimByNum?:  {
    __typename: "claim_info",
    car_reg?: string | null,
    claim_num?: string | null,
    description?: string | null,
    model?: string | null,
    policy_num?: string | null,
    recv_date?: string | null,
    state?: number | null,
    year?: number | null,
    id?: number | null,
    initial_amount?: number | null,
    final_amount?: number | null,
    age?: number | null,
    analyst?: string | null,
    notes?: string | null,
    location?: string | null,
  } | null,
};

export type CompanySettingsByCidQueryVariables = {
  cid: number,
};

export type CompanySettingsByCidQuery = {
  companySettingsByCid?:  {
    __typename: "CompanySettings",
    apppreference?: string | null,
    appworkflow?: string | null,
    checkinemailhtmlfile: string,
    cid: number,
    companyfieldsfile: string,
    companyname?: string | null,
    emailhtmlbucket: string,
    emailhtmlfile: string,
    emailsource: string,
    emailsubject: string,
    webpreference?: string | null,
  } | null,
};

export type DamageByImageQueryVariables = {
  image: string,
};

export type DamageByImageQuery = {
  damageByImage?:  {
    __typename: "ImageDamage",
    annotation?: string | null,
    annotationsummary?: number | null,
    cid: number,
    event?: string | null,
    image: string,
    json?: string | null,
  } | null,
};

export type DamageReviewQueryQueryVariables = {
  event: string,
};

export type DamageReviewQueryQuery = {
  damageReviewQuery?:  Array< {
    __typename: "RepDamage",
    action?: DamageAction | null,
    additionalinfo: string,
    part?: string | null,
    boundingbox: string,
    cid: number,
    confidence: number,
    datetime: string,
    duplicate: DamageDuplication,
    event: string,
    face: CarFaceType,
    id: string,
    image: string,
    preexistingcid_asset?: string | null,
    position: number,
    relatedregion?: string | null,
    score: number,
    sentiment?: DamageSentiment | null,
    sentimentnum?: number | null,
    severity?: DamageSeverity | null,
    source: DamageSource,
    statelicense: string,
    type?: DamageType | null,
  } | null > | null,
};

export type EventByIdQueryVariables = {
  id: string,
};

export type EventByIdQuery = {
  eventById?:  {
    __typename: "Event",
    cid: number,
    completed?: boolean | null,
    conditionProperties?: string | null,
    customerfueled?: boolean | null,
    custommetadata?: string | null,
    // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
    // # damage detection, 2 = TBD, 4 = TBD
    custompipeline?: number | null,
    datetime: string,
    distancetravelled?: number | null,
    eventtype: EventType,
    expectedfaces?: number | null,
    fuel?: string | null,
    id: string,
    imageTypes?: number | null,
    imagetotal?: number | null,
    invertedsentimentdatetime?: string | null,
    managerreviewed?: boolean | null,
    notificationTypes?: number | null,
    // The following support notifications. Initially for the API
    notificationURL?: string | null,
    observations?: string | null,
    odometer?: number | null,
    relatedcheckoutevent?: string | null,
    rentalagreement?: string | null,
    // Following is a bitcode where 0 means no alignment on any side and 15 all sides
    requestedalignment?: number | null,
    reviewstatus?: EventReviewStatus | null,
    risk?: Risk | null,
    sentiment?: DamageSentiment | null,
    sentimentdatetime?: string | null,
    signature?: string | null,
    sitename: string,
    statelicense: string,
    user?: string | null,
  } | null,
};

export type EventCustomerQueryVariables = {
  event: string,
};

export type EventCustomerQuery = {
  eventCustomer?:  {
    __typename: "Customer",
    datetime?: string | null,
    email?: string | null,
    event: string,
    phone?: string | null,
    status?: string | null,
  } | null,
};

export type EventsByRentalAgreementQueryVariables = {
  cid: number,
  rentalagreement: string,
};

export type EventsByRentalAgreementQuery = {
  // This uses an index such that only id, rentalagreement, eventtype and datetime
  // #  of Event are returned.
  eventsByRentalAgreement?:  Array< {
    __typename: "Event",
    cid: number,
    completed?: boolean | null,
    conditionProperties?: string | null,
    customerfueled?: boolean | null,
    custommetadata?: string | null,
    // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
    // # damage detection, 2 = TBD, 4 = TBD
    custompipeline?: number | null,
    datetime: string,
    distancetravelled?: number | null,
    eventtype: EventType,
    expectedfaces?: number | null,
    fuel?: string | null,
    id: string,
    imageTypes?: number | null,
    imagetotal?: number | null,
    invertedsentimentdatetime?: string | null,
    managerreviewed?: boolean | null,
    notificationTypes?: number | null,
    // The following support notifications. Initially for the API
    notificationURL?: string | null,
    observations?: string | null,
    odometer?: number | null,
    relatedcheckoutevent?: string | null,
    rentalagreement?: string | null,
    // Following is a bitcode where 0 means no alignment on any side and 15 all sides
    requestedalignment?: number | null,
    reviewstatus?: EventReviewStatus | null,
    risk?: Risk | null,
    sentiment?: DamageSentiment | null,
    sentimentdatetime?: string | null,
    signature?: string | null,
    sitename: string,
    statelicense: string,
    user?: string | null,
  } | null > | null,
};

export type FilteredDamageQueryVariables = {
  before?: string | null,
  cid: number,
  count?: number | null,
  filter?: DamageFilterInput | null,
  nextToken?: string | null,
  since?: string | null,
};

export type FilteredDamageQuery = {
  filteredDamage?:  {
    __typename: "PaginatedRepDamage",
    nextToken?: string | null,
    repDamages?:  Array< {
      __typename: "RepDamage",
      action?: DamageAction | null,
      additionalinfo: string,
      part?: string | null,
      boundingbox: string,
      cid: number,
      confidence: number,
      datetime: string,
      duplicate: DamageDuplication,
      event: string,
      face: CarFaceType,
      id: string,
      image: string,
      preexistingcid_asset?: string | null,
      position: number,
      relatedregion?: string | null,
      score: number,
      sentiment?: DamageSentiment | null,
      sentimentnum?: number | null,
      severity?: DamageSeverity | null,
      source: DamageSource,
      statelicense: string,
      type?: DamageType | null,
    } | null > | null,
  } | null,
};

export type FilteredDamageByCarQueryVariables = {
  before?: string | null,
  cid: number,
  count?: number | null,
  filter?: DamageFilterInput | null,
  nextToken?: string | null,
  since?: string | null,
  statelicense: string,
};

export type FilteredDamageByCarQuery = {
  filteredDamageByCar?:  {
    __typename: "PaginatedRepDamage",
    nextToken?: string | null,
    repDamages?:  Array< {
      __typename: "RepDamage",
      action?: DamageAction | null,
      additionalinfo: string,
      part?: string | null,
      boundingbox: string,
      cid: number,
      confidence: number,
      datetime: string,
      duplicate: DamageDuplication,
      event: string,
      face: CarFaceType,
      id: string,
      image: string,
      preexistingcid_asset?: string | null,
      position: number,
      relatedregion?: string | null,
      score: number,
      sentiment?: DamageSentiment | null,
      sentimentnum?: number | null,
      severity?: DamageSeverity | null,
      source: DamageSource,
      statelicense: string,
      type?: DamageType | null,
    } | null > | null,
  } | null,
};

export type FilteredEventQueryQueryVariables = {
  cid: number,
  count?: number | null,
  filter?: EventFilterInput | null,
  nextToken?: string | null,
};

export type FilteredEventQueryQuery = {
  filteredEventQuery?:  {
    __typename: "PaginatedEvent",
    events?:  Array< {
      __typename: "Event",
      cid: number,
      completed?: boolean | null,
      conditionProperties?: string | null,
      customerfueled?: boolean | null,
      custommetadata?: string | null,
      // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
      // # damage detection, 2 = TBD, 4 = TBD
      custompipeline?: number | null,
      datetime: string,
      distancetravelled?: number | null,
      eventtype: EventType,
      expectedfaces?: number | null,
      fuel?: string | null,
      id: string,
      imageTypes?: number | null,
      imagetotal?: number | null,
      invertedsentimentdatetime?: string | null,
      managerreviewed?: boolean | null,
      notificationTypes?: number | null,
      // The following support notifications. Initially for the API
      notificationURL?: string | null,
      observations?: string | null,
      odometer?: number | null,
      relatedcheckoutevent?: string | null,
      rentalagreement?: string | null,
      // Following is a bitcode where 0 means no alignment on any side and 15 all sides
      requestedalignment?: number | null,
      reviewstatus?: EventReviewStatus | null,
      risk?: Risk | null,
      sentiment?: DamageSentiment | null,
      sentimentdatetime?: string | null,
      signature?: string | null,
      sitename: string,
      statelicense: string,
      user?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllClaimsQuery = {
  getAllClaims?:  Array< {
    __typename: "claim_info",
    car_reg?: string | null,
    claim_num?: string | null,
    description?: string | null,
    model?: string | null,
    policy_num?: string | null,
    recv_date?: string | null,
    state?: number | null,
    year?: number | null,
    id?: number | null,
    initial_amount?: number | null,
    final_amount?: number | null,
    age?: number | null,
    analyst?: string | null,
    notes?: string | null,
    location?: string | null,
  } | null > | null,
};

export type GetAllClaimsForRepairerQueryVariables = {
  repairer_id?: number | null,
};

export type GetAllClaimsForRepairerQuery = {
  getAllClaimsForRepairer?:  Array< {
    __typename: "claim_repairer_info",
    car_reg?: string | null,
    claim_num?: string | null,
    description?: string | null,
    model?: string | null,
    policy_num?: string | null,
    recv_date?: string | null,
    state?: number | null,
    year?: number | null,
    age?: string | null,
  } | null > | null,
};

export type GetAssetPreexistingDamagesQueryVariables = {
  cid: number,
  statelicense: string,
};

export type GetAssetPreexistingDamagesQuery = {
  // Important: Only the RepDamage id is returned by this query.
  // # This is due to the index only including the id in the projected attributes.
  getAssetPreexistingDamages?:  Array< {
    __typename: "RepDamage",
    action?: DamageAction | null,
    additionalinfo: string,
    part?: string | null,
    boundingbox: string,
    cid: number,
    confidence: number,
    datetime: string,
    duplicate: DamageDuplication,
    event: string,
    face: CarFaceType,
    id: string,
    image: string,
    preexistingcid_asset?: string | null,
    position: number,
    relatedregion?: string | null,
    score: number,
    sentiment?: DamageSentiment | null,
    sentimentnum?: number | null,
    severity?: DamageSeverity | null,
    source: DamageSource,
    statelicense: string,
    type?: DamageType | null,
  } | null > | null,
};

export type GetDamagesForClaimQueryVariables = {
  claim_num?: string | null,
};

export type GetDamagesForClaimQuery = {
  getDamagesForClaim?:  Array< {
    __typename: "damage_info",
    image_url?: string | null,
    description?: string | null,
    location?: string | null,
    type?: string | null,
    car_reg?: string | null,
    car_id?: number | null,
    damage_id?: number | null,
  } | null > | null,
};

export type GetDamageRelatedRegionQueryVariables = {
  json?: string | null,
};

export type GetDamageRelatedRegionQuery = {
  // Returns the region that matches the region of the passed damage in the same corresponding face of
  // # the relatedevent.
  // # The input json string is:  {"damageid": "<string>", "relatedevent": "<string>"}
  // # The returned string is json: {"relatedposition": <int>, "boundingbox": "<AWSJSON same as RepDamage>"}
  getDamageRelatedRegion?:  {
    __typename: "JsonStringType",
    json: string,
  } | null,
};

export type GetGantryCameraDetailsQueryVariables = {
  cameraid: string,
};

export type GetGantryCameraDetailsQuery = {
  getGantryCameraDetails?:  {
    __typename: "GantryCameraDetails",
    cameraid: string,
    cid?: number | null,
    site?: string | null,
    gantry?: string | null,
    camera?: string | null,
    fps?: number | null,
    processindex?: number | null,
    additionaljson?: string | null,
  } | null,
};

export type GetGantryCamerasQueryVariables = {
  cid: number,
  site: string,
  gantry: string,
};

export type GetGantryCamerasQuery = {
  getGantryCameras?:  Array< {
    __typename: "GantryCameraDetails",
    cameraid: string,
    cid?: number | null,
    site?: string | null,
    gantry?: string | null,
    camera?: string | null,
    fps?: number | null,
    processindex?: number | null,
    additionaljson?: string | null,
  } | null > | null,
};

export type GetSimilarDamagesForClaimQueryVariables = {
  id?: number | null,
};

export type GetSimilarDamagesForClaimQuery = {
  getSimilarDamagesForClaim?:  Array< {
    __typename: "damage_info",
    image_url?: string | null,
    description?: string | null,
    location?: string | null,
    type?: string | null,
    car_reg?: string | null,
    car_id?: number | null,
    damage_id?: number | null,
  } | null > | null,
};

export type GetQuoteReqsForClaimQueryVariables = {
  claim_num?: string | null,
};

export type GetQuoteReqsForClaimQuery = {
  getQuoteReqsForClaim?:  Array< {
    __typename: "quote_req_info",
    state?: number | null,
    distance?: number | null,
    name?: string | null,
    Claim_Claim_id?: number | null,
    Claim_Repairer_id?: number | null,
  } | null > | null,
};

export type GetQuoteReqsForClaimAndRepairerQueryVariables = {
  claim_num?: string | null,
  repairer_id?: number | null,
};

export type GetQuoteReqsForClaimAndRepairerQuery = {
  getQuoteReqsForClaimAndRepairer?:  {
    __typename: "quote_req_info",
    state?: number | null,
    distance?: number | null,
    name?: string | null,
    Claim_Claim_id?: number | null,
    Claim_Repairer_id?: number | null,
  } | null,
};

export type GetQuotesFromRepairerForClaimQueryVariables = {
  claim_num?: string | null,
  repairer_id?: number | null,
};

export type GetQuotesFromRepairerForClaimQuery = {
  getQuotesFromRepairerForClaim?:  Array< {
    __typename: "quote_info",
    amount?: number | null,
    id: number,
    quote_num?: string | null,
    type?: string | null,
    Claim_id?: number | null,
    Claim_Repairer_id?: number | null,
  } | null > | null,
};

export type GetQuoteItemsForQuoteQueryVariables = {
  quote_id?: number | null,
};

export type GetQuoteItemsForQuoteQuery = {
  getQuoteItemsForQuote?:  Array< {
    __typename: "quote_item_info",
    id?: number | null,
    amount?: number | null,
    type?: string | null,
    description?: string | null,
    name?: string | null,
    discount?: number | null,
    gst?: number | null,
  } | null > | null,
};

export type GetDebugSinceQueryVariables = {
  datetime: string,
  debugtype: DebugType,
};

export type GetDebugSinceQuery = {
  getDebugSince?:  Array< {
    __typename: "Debug",
    datetime: string,
    debugtype?: DebugType | null,
    id: string,
    msg?: string | null,
  } | null > | null,
};

export type GetDevCrowdusersQueryVariables = {
  email: string,
};

export type GetDevCrowdusersQuery = {
  getDevCrowdusers?:  {
    __typename: "DevCrowdusers",
    email: string,
    uploaded?: number | null,
  } | null,
};

export type GetModelInfoQueryVariables = {
  modelid: string,
};

export type GetModelInfoQuery = {
  getModelInfo?:  {
    __typename: "CarModelInfo",
    bodystyle?: string | null,
    id: string,
    make?: string | null,
    maxheight?: number | null,
    maxtyre?: number | null,
    minheight?: number | null,
    mintyre?: number | null,
    model?: string | null,
    variant?: string | null,
  } | null,
};

export type GetModelInfoForAssetQueryVariables = {
  assetid: string,
};

export type GetModelInfoForAssetQuery = {
  getModelInfoForAsset?:  {
    __typename: "CarModelInfo",
    bodystyle?: string | null,
    id: string,
    make?: string | null,
    maxheight?: number | null,
    maxtyre?: number | null,
    minheight?: number | null,
    mintyre?: number | null,
    model?: string | null,
    variant?: string | null,
  } | null,
};

export type GetRelatedImageQueryVariables = {
  event: string,
  facetype: CarFaceType,
  originalpositioninface: number,
  relatedevent: string,
};

export type GetRelatedImageQuery = {
  getRelatedImage?:  {
    __typename: "Image",
    event: string,
    eventtype?: EventType | null,
    faceposition?: number | null,
    facetype?: CarFaceType | null,
    height?: number | null,
    id: string,
    // set by users manually
    istheredamage?: number | null,
    position?: number | null,
    processingstate?: string | null,
    s3path: string,
    selected?: boolean | null,
    totalCount?: number | null,
    width?: number | null,
  } | null,
};

export type GetReportsFromQueryVariables = {
  cid: number,
  count?: number | null,
  nextToken?: string | null,
  reportdatetime: string,
};

export type GetReportsFromQuery = {
  getReportsFrom?:  {
    __typename: "PaginatedReport",
    nextToken?: string | null,
    reports:  Array< {
      __typename: "Report",
      cid: number,
      comparedevent: string,
      id: string,
      items?: string | null,
      reportdatetime: string,
      reportevent: string,
      status: number,
      text?: string | null,
    } >,
  } | null,
};

export type GetS3ImageListQueryVariables = {
  bucket?: string | null,
  imagetype?: string | null,
  s3folder?: string | null,
};

export type GetS3ImageListQuery = {
  // To retrieve extra images: getS3ImageList(bucket: "<your bucket>", s3folder: "mobile/<event id without slash>", imagetype: "extra") {
  getS3ImageList?:  Array< {
    __typename: "S3Image",
    filename?: string | null,
    imagetype?: string | null,
    position?: number | null,
    s3path: string,
  } | null > | null,
};

export type GetVersionInfoQueryVariables = {
  cid?: number | null,
  sitename?: string | null,
  version: string,
};

export type GetVersionInfoQuery = {
  getVersionInfo?:  {
    __typename: "VersionInfo",
    deprecationmessage?: string | null,
    severity?: number | null,
    version: string,
  } | null,
};

export type ImageByEventQueryVariables = {
  event: string,
};

export type ImageByEventQuery = {
  // Return the event's images. This only returns selected images. See imageByEventAll.
  imageByEvent?:  Array< {
    __typename: "Image",
    event: string,
    eventtype?: EventType | null,
    faceposition?: number | null,
    facetype?: CarFaceType | null,
    height?: number | null,
    id: string,
    // set by users manually
    istheredamage?: number | null,
    position?: number | null,
    processingstate?: string | null,
    s3path: string,
    selected?: boolean | null,
    totalCount?: number | null,
    width?: number | null,
  } | null > | null,
};

export type ImageByEventAllQueryVariables = {
  event: string,
};

export type ImageByEventAllQuery = {
  // Return the event's images. This returns all images, selected and not selected.
  imageByEventAll?:  Array< {
    __typename: "Image",
    event: string,
    eventtype?: EventType | null,
    faceposition?: number | null,
    facetype?: CarFaceType | null,
    height?: number | null,
    id: string,
    // set by users manually
    istheredamage?: number | null,
    position?: number | null,
    processingstate?: string | null,
    s3path: string,
    selected?: boolean | null,
    totalCount?: number | null,
    width?: number | null,
  } | null > | null,
};

export type ImageByEventAndPositionQueryVariables = {
  event: string,
  position: number,
};

export type ImageByEventAndPositionQuery = {
  imageByEventAndPosition?:  Array< {
    __typename: "Image",
    event: string,
    eventtype?: EventType | null,
    faceposition?: number | null,
    facetype?: CarFaceType | null,
    height?: number | null,
    id: string,
    // set by users manually
    istheredamage?: number | null,
    position?: number | null,
    processingstate?: string | null,
    s3path: string,
    selected?: boolean | null,
    totalCount?: number | null,
    width?: number | null,
  } | null > | null,
};

export type ImageByIdQueryVariables = {
  id: string,
};

export type ImageByIdQuery = {
  imageById?:  {
    __typename: "Image",
    event: string,
    eventtype?: EventType | null,
    faceposition?: number | null,
    facetype?: CarFaceType | null,
    height?: number | null,
    id: string,
    // set by users manually
    istheredamage?: number | null,
    position?: number | null,
    processingstate?: string | null,
    s3path: string,
    selected?: boolean | null,
    totalCount?: number | null,
    width?: number | null,
  } | null,
};

export type ListDevCrowdusersQueryVariables = {
  filter?: TableDevCrowdusersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDevCrowdusersQuery = {
  listDevCrowdusers?:  {
    __typename: "DevCrowdusersConnection",
    items?:  Array< {
      __typename: "DevCrowdusers",
      email: string,
      uploaded?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type MaskByImageQueryVariables = {
  image: string,
};

export type MaskByImageQuery = {
  maskByImage?:  {
    __typename: "ImageMask",
    image: string,
    json?: string | null,
    masks3path?: string | null,
    event?: string | null,
  } | null,
};

export type MasksByEventQueryVariables = {
  event: string,
};

export type MasksByEventQuery = {
  masksByEvent?:  Array< {
    __typename: "ImageMask",
    image: string,
    json?: string | null,
    masks3path?: string | null,
    event?: string | null,
  } | null > | null,
};

export type RelatedCheckOutQueryVariables = {
  count?: number | null,
  statelicense?: string | null,
};

export type RelatedCheckOutQuery = {
  relatedCheckOut?:  Array< {
    __typename: "Event",
    cid: number,
    completed?: boolean | null,
    conditionProperties?: string | null,
    customerfueled?: boolean | null,
    custommetadata?: string | null,
    // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
    // # damage detection, 2 = TBD, 4 = TBD
    custompipeline?: number | null,
    datetime: string,
    distancetravelled?: number | null,
    eventtype: EventType,
    expectedfaces?: number | null,
    fuel?: string | null,
    id: string,
    imageTypes?: number | null,
    imagetotal?: number | null,
    invertedsentimentdatetime?: string | null,
    managerreviewed?: boolean | null,
    notificationTypes?: number | null,
    // The following support notifications. Initially for the API
    notificationURL?: string | null,
    observations?: string | null,
    odometer?: number | null,
    relatedcheckoutevent?: string | null,
    rentalagreement?: string | null,
    // Following is a bitcode where 0 means no alignment on any side and 15 all sides
    requestedalignment?: number | null,
    reviewstatus?: EventReviewStatus | null,
    risk?: Risk | null,
    sentiment?: DamageSentiment | null,
    sentimentdatetime?: string | null,
    signature?: string | null,
    sitename: string,
    statelicense: string,
    user?: string | null,
  } | null > | null,
};

export type RelatedCheckOut_V2QueryVariables = {
  cid: number,
  count?: number | null,
  statelicense: string,
};

export type RelatedCheckOut_V2Query = {
  relatedCheckOut_V2?:  Array< {
    __typename: "Event",
    cid: number,
    completed?: boolean | null,
    conditionProperties?: string | null,
    customerfueled?: boolean | null,
    custommetadata?: string | null,
    // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
    // # damage detection, 2 = TBD, 4 = TBD
    custompipeline?: number | null,
    datetime: string,
    distancetravelled?: number | null,
    eventtype: EventType,
    expectedfaces?: number | null,
    fuel?: string | null,
    id: string,
    imageTypes?: number | null,
    imagetotal?: number | null,
    invertedsentimentdatetime?: string | null,
    managerreviewed?: boolean | null,
    notificationTypes?: number | null,
    // The following support notifications. Initially for the API
    notificationURL?: string | null,
    observations?: string | null,
    odometer?: number | null,
    relatedcheckoutevent?: string | null,
    rentalagreement?: string | null,
    // Following is a bitcode where 0 means no alignment on any side and 15 all sides
    requestedalignment?: number | null,
    reviewstatus?: EventReviewStatus | null,
    risk?: Risk | null,
    sentiment?: DamageSentiment | null,
    sentimentdatetime?: string | null,
    signature?: string | null,
    sitename: string,
    statelicense: string,
    user?: string | null,
  } | null > | null,
};

export type RepDamageByEventFaceQueryVariables = {
  event: string,
  face: CarFaceType,
};

export type RepDamageByEventFaceQuery = {
  repDamageByEventFace?:  Array< {
    __typename: "RepDamage",
    action?: DamageAction | null,
    additionalinfo: string,
    part?: string | null,
    boundingbox: string,
    cid: number,
    confidence: number,
    datetime: string,
    duplicate: DamageDuplication,
    event: string,
    face: CarFaceType,
    id: string,
    image: string,
    preexistingcid_asset?: string | null,
    position: number,
    relatedregion?: string | null,
    score: number,
    sentiment?: DamageSentiment | null,
    sentimentnum?: number | null,
    severity?: DamageSeverity | null,
    source: DamageSource,
    statelicense: string,
    type?: DamageType | null,
  } | null > | null,
};

export type RepDamageByIDQueryVariables = {
  id: string,
};

export type RepDamageByIDQuery = {
  repDamageByID?:  {
    __typename: "RepDamage",
    action?: DamageAction | null,
    additionalinfo: string,
    part?: string | null,
    boundingbox: string,
    cid: number,
    confidence: number,
    datetime: string,
    duplicate: DamageDuplication,
    event: string,
    face: CarFaceType,
    id: string,
    image: string,
    preexistingcid_asset?: string | null,
    position: number,
    relatedregion?: string | null,
    score: number,
    sentiment?: DamageSentiment | null,
    sentimentnum?: number | null,
    severity?: DamageSeverity | null,
    source: DamageSource,
    statelicense: string,
    type?: DamageType | null,
  } | null,
};

export type ReportByIDQueryVariables = {
  id: string,
};

export type ReportByIDQuery = {
  reportByID?:  {
    __typename: "Report",
    cid: number,
    comparedevent: string,
    id: string,
    items?: string | null,
    reportdatetime: string,
    reportevent: string,
    status: number,
    text?: string | null,
  } | null,
};

export type ReportsByEventQueryVariables = {
  reportevent: string,
};

export type ReportsByEventQuery = {
  reportsByEvent?:  {
    __typename: "PaginatedReport",
    nextToken?: string | null,
    reports:  Array< {
      __typename: "Report",
      cid: number,
      comparedevent: string,
      id: string,
      items?: string | null,
      reportdatetime: string,
      reportevent: string,
      status: number,
      text?: string | null,
    } >,
  } | null,
};

export type ReviewEventQueryQueryVariables = {
  accumulate?: boolean | null,
  cid: number,
  count?: number | null,
  dateorder?: ReviewDateOrdering | null,
  filter?: ReviewEventFilterInput | null,
  nextToken?: string | null,
  sentimentorder?: ReviewSentimentOrdering | null,
};

export type ReviewEventQueryQuery = {
  // Defaults: count is 20; dateorder is DESC; sentimentorder is NONE.
  reviewEventQuery?:  {
    __typename: "PaginatedReviewEvent",
    nextToken?: string | null,
    reviewEvents?:  Array< {
      __typename: "ReviewEvent",
      cid: number,
      conditionProperties?: string | null,
      datetime: string,
      eventtype?: EventType | null,
      expectedfaces?: number | null,
      fuel?: string | null,
      id: string,
      imagetotal?: number | null,
      odometer?: number | null,
      relatedcheckoutevent?: string | null,
      rentalagreement?: string | null,
      reviewstatus?: EventReviewStatus | null,
      sentiment?: DamageSentiment | null,
      signature?: string | null,
      sitename?: string | null,
      statelicense?: string | null,
    } | null > | null,
  } | null,
};

export type SinceFilteredEventQueryQueryVariables = {
  before?: string | null,
  cid: number,
  count?: number | null,
  filter?: EventFilterInput_v2 | null,
  nextToken?: string | null,
  since?: string | null,
};

export type SinceFilteredEventQueryQuery = {
  sinceFilteredEventQuery?:  {
    __typename: "PaginatedEvent",
    events?:  Array< {
      __typename: "Event",
      cid: number,
      completed?: boolean | null,
      conditionProperties?: string | null,
      customerfueled?: boolean | null,
      custommetadata?: string | null,
      // Bitmap of alternate pipeline processing: 0 (default) = standard pipeline, 1 = use reduced images for
      // # damage detection, 2 = TBD, 4 = TBD
      custompipeline?: number | null,
      datetime: string,
      distancetravelled?: number | null,
      eventtype: EventType,
      expectedfaces?: number | null,
      fuel?: string | null,
      id: string,
      imageTypes?: number | null,
      imagetotal?: number | null,
      invertedsentimentdatetime?: string | null,
      managerreviewed?: boolean | null,
      notificationTypes?: number | null,
      // The following support notifications. Initially for the API
      notificationURL?: string | null,
      observations?: string | null,
      odometer?: number | null,
      relatedcheckoutevent?: string | null,
      rentalagreement?: string | null,
      // Following is a bitcode where 0 means no alignment on any side and 15 all sides
      requestedalignment?: number | null,
      reviewstatus?: EventReviewStatus | null,
      risk?: Risk | null,
      sentiment?: DamageSentiment | null,
      sentimentdatetime?: string | null,
      signature?: string | null,
      sitename: string,
      statelicense: string,
      user?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SiteByIdQueryVariables = {
  sitename?: string | null,
};

export type SiteByIdQuery = {
  siteById?:  {
    __typename: "Site",
    brand?: string | null,
    cid: number,
    company_name?: string | null,
    country?: string | null,
    district?: string | null,
    location?: string | null,
    region?: string | null,
    site?: string | null,
    siteid?: number | null,
    sitename: string,
    state?: string | null,
    statusrequest?: number | null,
  } | null,
};

export type OnCreateDevCrowdusersSubscriptionVariables = {
  email?: string | null,
  uploaded?: number | null,
};

export type OnCreateDevCrowdusersSubscription = {
  onCreateDevCrowdusers?:  {
    __typename: "DevCrowdusers",
    email: string,
    uploaded?: number | null,
  } | null,
};

export type OnDeleteDevCrowdusersSubscriptionVariables = {
  email?: string | null,
  uploaded?: number | null,
};

export type OnDeleteDevCrowdusersSubscription = {
  onDeleteDevCrowdusers?:  {
    __typename: "DevCrowdusers",
    email: string,
    uploaded?: number | null,
  } | null,
};

export type OnUpdateDevCrowdusersSubscriptionVariables = {
  email?: string | null,
  uploaded?: number | null,
};

export type OnUpdateDevCrowdusersSubscription = {
  onUpdateDevCrowdusers?:  {
    __typename: "DevCrowdusers",
    email: string,
    uploaded?: number | null,
  } | null,
};
