import { combineReducers } from 'redux';
import authReducer from './auth/auth.reducer';
import configurationReducer from './configuration/configuration.reducer';
import locationsReducer from './locations/locations.reducer';
import lookupReducer from './lookup/lookup.reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  locations: locationsReducer,
  configuration: configurationReducer,
  lookup: lookupReducer,
});
