import React from 'react';
import Sidebar from '../Sidebar';
import { Layout } from 'antd';

export interface IMainLayoutProps {
  children?: React.ReactNode;
  side: boolean; // Show sidebar if true
}

const MainLayout: React.FC<IMainLayoutProps> = props => {
  const renderSideBar = (doRender: boolean) => {
    if (doRender) {
      return <Layout.Sider className="sidebar" style={{ minWidth: '220px' }}>
        <Sidebar />
      </Layout.Sider>
    } else {
      return <></>
    }
  }

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        { renderSideBar(props.side)}
        {props.children}
      </Layout>
    </>
  );
};

export default MainLayout;
