import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { CONFIG_FIELD_LANGUAGE, getConfiguration } from '../utility/constants/customisation';
import { getDefaultLanguage } from '../utility/language';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: getConfiguration(CONFIG_FIELD_LANGUAGE, getDefaultLanguage()),
  resources: {
    en: {
      translations: require('./locales/en/translations.json'),
    },
    id: {
      translations: require('./locales/in/translations.json'),
    },
  },
  defaultNS: 'translations',
  returnNull: false,
});

i18n.languages = ['en', 'in'];

export default i18n;
