import { Menu } from 'antd';
import { Auth } from 'aws-amplify';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/app.hooks';
import { signOut as logOut } from '../../store/auth/auth.reducer';
import {
  clearConfiguration,
  configurationSelector,
} from '../../store/configuration/configuration.reducer';
import {
  clearLocations,
  locationsSelector,
  setLocationPreferences,
} from '../../store/locations/locations.reducer';
import {
  CID_AAMG_RENTAL,
  LOGO_TYPES,
  getCid,
  getImage,
  getMenuLabelFor,
  hideMenuItems,
  isRentalFinder,
  isSompo,
} from '../../utility/constants/customisation';
import { AppRoutings } from '../../utility/enums/app-routings';
import { encodeCID } from '../../utility/signin';
import { KEY_LS_ALLOWED_SITES, KEY_LS_USERNAME } from '../../utility/constants';

const Sidebar: React.FC = () => {
  const appConfig = useAppSelector(configurationSelector);
  const locations = useAppSelector(locationsSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useNavigate();

  const rentalMenuItems = [
    {
      key: '/dashboard-rental',
      label: 'Dashboard',
      icon: 'dashboard',
      url: '/dashboard-rental',
      hidden: false,
    },
    {
      key: '/reviews',
      label: getMenuLabelFor('reviews'),
      icon: 'reviews',
      url: '/reviews',
      hidden: false,
    },
    {
      key: '/annotations',
      label: 'Annotations',
      icon: 'edit',
      url: '/annotations',
      hidden: false,
    },
    {
      key: '/assets',
      label: 'Assets',
      icon: 'assets',
      url: '/assets',
      hidden: false,
    },
    {
      key: '/scans',
      label: 'Scans',
      icon: 'scans',
      url: '/scans',
      hidden: false,
    },
    {
      key: '/scansreviewlist',
      label: 'Scans',
      icon: 'scansdealers',
      url: '/scansreviewlist',
      hidden: false,
    },
    {
      key: '/comparisons',
      label: 'Comparisons',
      icon: 'pairedscan',
      url: '/comparisons',
      hidden: false,
    },
    {
      key: '/comparisonsdealers',
      label: 'Comparisons',
      icon: 'pairedscan',
      url: '/comparisonsdealers',
      hidden: false,
    },
    {
      key: '/comparisonreports',
      label: 'Reports', // 'Comparison reports',
      icon: 'comparisonreports',
      url: '/comparisonreports',
      hidden: false,
    },
    {
      key: '/newpolicies',
      label: 'New Policies',
      icon: 'scans',
      url: '/newpolicies',
      hidden: false,
    },
    {
      key: '/newclaims',
      label: isSompo() ? 'Claim' : 'New Claims', // TODO: very confusing, but Sompo insisted
      icon: 'claims',
      url: '/newclaims',
      hidden: false,
    },
    {
      key: AppRoutings.WorkshopFinder,
      label: isRentalFinder() ? 'Rental Finder' : 'Workshop',
      icon: 'workshop',
      url: AppRoutings.WorkshopFinder,
      hidden: false,
    },
    {
      key: AppRoutings.CaptureCheck,
      label: 'Capture check',
      icon: 'capturecheck',
      url: AppRoutings.CaptureCheck,
      hidden: false,
    },
    {
      key: AppRoutings.RequestScan,
      label: 'Request scan',
      icon: 'requestscan',
      url: AppRoutings.RequestScan,
      hidden: false,
    },
    {
      key: AppRoutings.Claims,
      label: 'Claims assess',
      icon: 'claims',
      url: AppRoutings.Claims,
      hidden: false,
    },
    {
      key: '/locations',
      label: 'Locations',
      icon: 'locations',
      url: '/locations',
      hidden: false,
    },
    {
      key: '/settings',
      label: 'Options',
      icon: 'options',
      url: '/settings',
      hidden: false,
    },
    {
      key: '/admin',
      label: 'Admin',
      icon: 'settings',
      url: '/admin',
      hidden: false,
    },
    {
      key: '/scan-devices',
      label: 'Scan Devices',
      icon: 'scan_devices',
      url: '/scan-devices',
      hidden: true,
    },
  ];

  const claimsMenuItems = [
    {
      key: '/dashboard-claims',
      label: 'Dashboard',
      icon: 'dashboard',
      url: '/dashboard-claims',
      hidden: false,
    },
    {
      key: '/claims',
      label: 'Claims',
      icon: 'reviews',
      url: '/claims',
      hidden: false,
    },
    {
      key: '/repair-requests',
      label: 'Repair Requests',
      icon: 'reviews',
      url: '/repair-requests',
      hidden: false,
    },
    {
      key: '/users',
      label: 'Users',
      icon: 'users',
      url: '/users',
      hidden: false,
    },
    {
      key: '/settings',
      label: 'Settings',
      icon: 'configuration',
      url: '/settings',
      hidden: false,
    },
  ];

  const signOut = async () => {
    try {
      await Auth.signOut();
      const currentUser = localStorage.getItem(KEY_LS_USERNAME);
      const selectedLocations = localStorage.getItem(KEY_LS_ALLOWED_SITES);
      if (currentUser && selectedLocations) {
        const locationsForUser = {
          ...locations.locationPreferences,
          [currentUser]: JSON.parse(selectedLocations),
        };
        dispatch(setLocationPreferences(locationsForUser));
      }
      dispatch(logOut());
      dispatch(clearLocations());
      dispatch(clearConfiguration());
      history({
        pathname: AppRoutings.SignIn,
        search: '?cid=' + encodeCID(getCid()),
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const getRentalMenuItems = () => {
    const result = rentalMenuItems.map((a) => ({
      ...a,
      className: 'menu-item',
      icon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/icon_sidemenu_${a.icon}.svg`}
          alt=""
          width="14"
        />
      ),
    }));

    for (let i = 0; i < result.length; i++) {
      if (hideMenuItems(result[i].key)) {
        result[i].hidden = true;
      }
    }
    console.log('Menu', result);
    /*
    if (hideMenuItems("/reviews")) { // Sompo and Guerin
      // TODO: this should go by key rather than index number
      result[1].hidden = true; // Hide reviews
    }
    if (hideMenuItems("/scans")) { // Sompo and Guerin
      // TODO: this should go by key rather than index number
      result[2].hidden = true; // Hide scans
    }
    if (!isAdmin()) {
      result[6].hidden = true; // Hide admin
    }
    */
    return result;
  };

  // TODO: merging rental and claims into same workflow
  const menuItems = getRentalMenuItems();
  /*
  const menuItems =
    appConfig.configuration.workflow && appConfig.configuration.workflow === 'claims'
      ? claimsMenuItems
      : getRentalMenuItems();
      */

  const onSelectMenu = (item: any): void => {
    history(item.key);
  };

  return (
    <>
      <div className="sidebar-logo">
        {getCid() == CID_AAMG_RENTAL ? (
          <>
            <a href="https://www.carbiz.com.au/">
              <img
                src={`${getImage(LOGO_TYPES.SIDEBAR)}`}
                height={25}
                alt=""
                className="sidebar-logo-img"
              />
            </a>
          </>
        ) : (
          <>
            <img
              src={`${getImage(LOGO_TYPES.SIDEBAR)}`}
              height={25}
              alt=""
              className="sidebar-logo-img"
              onClick={() => {
                history('/dashboard-rental');
              }}
            />
          </>
        )}
      </div>
      <Menu
        theme="dark"
        style={{ backgroundColor: '#141B29' }}
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        selectedKeys={[location.pathname]}
        onClick={onSelectMenu}
        items={menuItems}
      ></Menu>
      <div className="sign-out">
        <Link to="#" title="Sign Out" onClick={signOut}>
          Sign Out
        </Link>
      </div>
    </>
  );
};
export default Sidebar;
