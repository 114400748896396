import { COUNTRY_CODES } from './countries';

export type ValidationResult = {
  validated: boolean;
  message?: string | undefined;
};

const REGO_JURISDICTIONS = {
  [COUNTRY_CODES.INDONESIA]: {
    A: 'Banten',
    B: 'DKI Jakarta',
    D: 'Bandung',
    E: 'Cirebon',
    F: 'Bogor',
    G: 'Pekalongan',
    H: 'Semarang',
    K: 'Pati',
    L: 'Surabaya',
    M: 'Madura',
    N: 'Malang',
    P: 'Besuki',
    R: 'Banyumas',
    S: 'Bojonegoro',
    T: 'Kerawang',
    AA: 'Kedu',
    AB: 'DI Yogyakarta',
    AD: 'Surakarta',
    AE: 'Madiun',
    AG: 'Kediri',
    BA: 'Sumatra Barat',
    BB: 'Sumatra Utara',
    BD: 'Bengkulu',
    BE: 'Lampung',
    BG: 'Sumatra Selatan',
    BH: 'Jambi',
    BK: 'Sumatra Timur',
    BL: 'Aceh',
    BM: 'Riau',
    BN: 'Bangka',
    CC: 'Korps Konsul',
    CD: 'Korps Diplomatik',
    DA: 'Kalimantan Selatan',
    DB: 'Minahasa',
    DD: 'Sulawesi Selatan',
    DE: 'Maluku Selatan',
    DH: 'Maluku Timur',
    DG: 'Maluku Utara',
    DL: 'Sangihe - Talaud',
    DK: 'Bali',
    DN: 'Sulawesi Tengah',
    DM: 'Sulawesi Utara',
    DR: 'Lombok',
    PA: 'Irian Jaya',
    EA: 'Sumbawa',
    EB: 'Flores',
    ED: 'Sumba',
    KB: 'Kalimantan Barat',
    KT: 'Kalimantan Timur',
    W: 'Sidoarjo (Jawa Timur)',
    Z: 'Sumedang',
  },
};

export const validateLicensePlate = (text: string, jurisdiction: string): ValidationResult => {
  const valueTrimmed = text.replace(/ /g, '').toUpperCase();
  if (valueTrimmed.length == 0) {
    return { validated: false };
  }
  if (jurisdiction == COUNTRY_CODES.INDONESIA) {
    if (valueTrimmed.length < 3) {
      // This is not just validation, but also avoiding array index oob in next step
      return { validated: false, message: 'Too short' };
    } else {
      const area_code = valueTrimmed.substring(
        0,
        /^[A-Z]$/.test(valueTrimmed.substring(1, 2)) ? 2 : 1 // 1 or 2 letter area_code
      );
      console.log('Area', area_code);
      const region = REGO_JURISDICTIONS[jurisdiction][area_code];
      console.log('Region', region);
      if (!region) {
        return { validated: false, message: 'Invalid area code' };
      }
      const valid = /^[A-Z]{1,2}\d{1,4}[A-Z]{1,3}$/.test(valueTrimmed);
      return { validated: valid, message: valid ? region : 'Number plate does not match pattern' };
    }
  }
  return { validated: true }; // default
};

export const displayRego = (statelicense: string, showState: boolean) => {
  let result: string = statelicense ? statelicense : '';
  if (!showState) {
    result = result.split('-').slice(-1)[0];
  }
  return result;
};
