import { toast } from 'react-toastify';
import { updateLocationsInLocalStorage } from '..';
import {
  KEY_LS_ADMINTYPE,
  KEY_LS_ALLOWED_SITES,
  KEY_LS_CID,
  KEY_LS_COGNITOEMAIL,
  KEY_LS_USERNAME,
  KEY_LS_USERTYPE,
  KEY_Q_CID,
} from '../constants';
import { Buffer } from 'buffer';

export const encodeCID = (value: number): string => {
  let hex = value.toString(16);
  while (hex.length % 3 != 2) {
    hex = '0' + hex;
  }
  const chk = (value % 3) + ((value % 11) + '').padStart(2, '0');

  const b64 = Buffer.from(hex + '-' + chk, 'utf8').toString('base64');
  const encoding = b64;
  return encoding;
};

export const decodeCID = (encoding: string): number => {
  const a64 = Buffer.from(encoding, 'base64').toString('utf8');
  const sp = a64.split('-');
  const hexOut = sp[0];
  const original = parseInt(hexOut, 16);
  if (isNaN(original)) {
    return -1;
  }
  const check = encodeCID(original);
  if (encoding != check) {
    return -1;
  }
  return original;
};

export const setUserOnSignin = (
  username,
  cid,
  token,
  cloudEnvId,
  locations,
  allowedSites,
  adminType,
  userType,
  cognitoEmail
) => {
  localStorage.setItem(KEY_LS_USERNAME, username);
  localStorage.setItem(KEY_LS_CID, cid);
  localStorage.setItem('authToken', token);
  localStorage.setItem(KEY_LS_ADMINTYPE, adminType);
  localStorage.setItem(KEY_LS_USERTYPE, userType);
  localStorage.setItem(KEY_LS_COGNITOEMAIL, cognitoEmail);

  const currentEnv = String(localStorage.getItem('CloudEnvId'));
  if (currentEnv != cloudEnvId) {
    console.log(
      'Server configuration issue, expected env ',
      currentEnv,
      ' but credentials for ',
      cloudEnvId
    );
    toast.warning("Please check you're on the correct server, there is a credentials mismatch.");
  }
  if ('locationPreferences' in locations && username in locations.locationPreferences) {
    // Preserve existing
    // TODO: what if a permission for a site was withdrawn? Or is that covered already in update?
    localStorage.setItem(
      KEY_LS_ALLOWED_SITES,
      JSON.stringify(locations.locationPreferences[username])
    );
  }
  // Create or update
  updateLocationsInLocalStorage(allowedSites);
};

export const getUnverifiedCid = (searchParams) => {
  const q = searchParams != null ? searchParams.get(KEY_Q_CID) : null;
  let tmp = q == null ? -1 : decodeCID(q);
  if (tmp < 0) {
    tmp = Number(localStorage.getItem(KEY_LS_CID));
    console.log('Tmp loc', tmp);
    console.log(localStorage);
  }
  return tmp;
};

export const getUserPermissions = (email: string) => {
  // TODO: database call
  const result = {
    cid: -1,
    cloudEnvId: 1,
    allowedSites: '',
    adminType: -1,
    userType: -1,
  };
  if (email.includes('@dannycohenprooftec.onmicrosoft.com')) {
    result.cid = 1;
    result.cloudEnvId = 1;
    result.allowedSites = 'demo_paris;demo_test';
  }
  return result;
};
