import { RootState } from './../app.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IConfigurationState {
  configuration: any;
}

export const initialState: IConfigurationState = {
  configuration: {},
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfiguration: (state, action: PayloadAction<any>) => {
      state.configuration = JSON.parse(action.payload);
    },
    clearConfiguration: (state) => {
      state.configuration = {};
    },
  },
});

// A selector
export const configurationSelector = (state: RootState) => state.configuration;

// Actions
export const { setConfiguration, clearConfiguration } = configurationSlice.actions;

// The reducer
export default configurationSlice.reducer;
