/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  210750284236: {
    aws_project_region: 'ap-southeast-2',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_IrdAZTXcw',
    aws_user_pools_web_client_id: '5f7fju3mjireq205ovnss2ctot',
    oauth: {},
    aws_appsync_graphqlEndpoint:
      'https://oczgotcpizfdznaysmxsqokime.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    aws_appsync_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-anhyzsqfvvgqlhrzuxzeatw6o4',
  },
  211125761638: {
    aws_project_region: 'ap-southeast-2',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_OSEdenveU',
    aws_user_pools_web_client_id: '12f06jqj77f76elhf909ppl3k4',
    oauth: {},
    aws_appsync_graphqlEndpoint:
      'https://uxqwodxkljb2bbpwmvcnhtbe7e.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    aws_appsync_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-lsvmgc3pl5awpgf33fvmjwb3ty',
  },
};

export default awsmobile;
